import * as React from "react"

function SvgAtom(props) {
    const size = props.size || 24
    const color = props.color || "#5A3ADF"
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 48 48"
            fill="none"
            {...props}
        >
            <path
                d="M6.204 41.796c1.396 1.398 3.392 2.136 5.774 2.136 3.484 0 7.71-1.556 12.024-4.254 4.312 2.7 8.54 4.254 12.024 4.254 2.38 0 4.376-.738 5.774-2.136 2.538-2.538 2.822-6.826.802-12.078-.716-1.864-1.708-3.79-2.914-5.718 1.208-1.928 2.198-3.854 2.914-5.718 2.02-5.252 1.734-9.542-.802-12.078-1.396-1.398-3.392-2.136-5.774-2.136-3.484 0-7.71 1.556-12.024 4.254-4.312-2.7-8.54-4.254-12.024-4.254-2.38 0-4.376.738-5.774 2.136C3.666 8.742 3.38 13.03 5.4 18.282c.718 1.864 1.708 3.79 2.914 5.718-1.208 1.928-2.198 3.854-2.914 5.718-2.02 5.252-1.734 9.54.804 12.078zm32.662-10.642c1.378 3.58 1.416 6.502.104 7.814-.64.64-1.63.964-2.946.964-2.334 0-5.292-1.006-8.416-2.76a52.897 52.897 0 009.566-9.568 27.869 27.869 0 011.692 3.55zM24 34.834a47.136 47.136 0 01-5.868-4.966 47.997 47.997 0 01-5-5.868 47.997 47.997 0 015-5.868A47.482 47.482 0 0124 13.166a47.136 47.136 0 015.868 4.966 47.997 47.997 0 015 5.868 47.997 47.997 0 01-5 5.868A47.482 47.482 0 0124 34.834zM36.024 8.068c1.314 0 2.304.324 2.946.964 1.312 1.312 1.276 4.234-.104 7.814a27.869 27.869 0 01-1.692 3.55 52.897 52.897 0 00-9.566-9.568c3.124-1.752 6.082-2.76 8.416-2.76zm-26.89 8.778c-1.378-3.58-1.416-6.502-.104-7.814.64-.64 1.63-.964 2.946-.964 2.334 0 5.292 1.006 8.416 2.76a52.897 52.897 0 00-9.566 9.568 27.869 27.869 0 01-1.692-3.55zm0 14.308a27.869 27.869 0 011.692-3.55 52.897 52.897 0 009.566 9.568c-3.126 1.754-6.082 2.76-8.416 2.76-1.314 0-2.304-.324-2.946-.964-1.312-1.312-1.274-4.234.104-7.814z"
                fill={color}
            />
            <path
                d="M24 29.148a5.148 5.148 0 100-10.297 5.148 5.148 0 000 10.297z"
                fill={color}
            />
        </svg>
    )
}

export default SvgAtom
