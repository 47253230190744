import React, { useEffect } from "react"
import styled from "styled-components/macro"

import Mobile from "./Previews/Mobile/Mobile"
import Website from "./Previews/Website"

import useWidthObserver from "../utils/useWidthObserver"

import { WEBSITE_SIZE } from "../utils/constants"

const Preview = ({ previewMedium, previewWebsiteWidth, forceWidth }) => {
    const [sizeIs, ref] = useWidthObserver()
    useEffect(() => {
        if (sizeIs.width) {
            if (sizeIs.width < 768) {
                forceWidth(WEBSITE_SIZE.MOBILE)
            } else if (sizeIs.width < 1200) {
                forceWidth(WEBSITE_SIZE.TABLET)
            } else {
                forceWidth(WEBSITE_SIZE.DESKTOP)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceWidth, previewWebsiteWidth, sizeIs])

    return (
        <StyledPreview ref={ref}>
            {previewMedium === "Mobile app" && <Mobile />}
            {previewMedium === "Website" && (
                <Website width={previewWebsiteWidth} isMobile={sizeIs.mobile} />
            )}
        </StyledPreview>
    )
}

const StyledPreview = styled.aside`
    display: flex;
    align-content: center;
    justify-content: center;
    img {
        align-self: center;
    }
`

export default Preview
