/*
    Color contrast helper functions.
    https://dev.to/alvaromontoro/building-your-own-color-contrast-checker-4j7o
*/

export function hexToRgb(hex) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b
    })

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
        ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16)
          }
        : null
}

export function extractRgb(rgbString) {
    const result = /(rgb|rgba)\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)/i.exec(
        rgbString
    )
    return result
        ? {
              r: parseInt(result[2], 16),
              g: parseInt(result[3], 16),
              b: parseInt(result[4], 16)
          }
        : null
}

function getRgb(hexOrRgb) {
    if (hexOrRgb.includes("rgb")) {
        return extractRgb(hexOrRgb)
    }
    return hexToRgb(hexOrRgb)
}

export function luminance(r, g, b) {
    const a = [r, g, b].map(function (v) {
        v /= 255
        return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
    })
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
}

export function ratio(colorOneLuminance, colorTwoLuminance) {
    return colorOneLuminance > colorTwoLuminance
        ? (colorTwoLuminance + 0.05) / (colorOneLuminance + 0.05)
        : (colorOneLuminance + 0.05) / (colorTwoLuminance + 0.05)
}

export function resultSet(ratio) {
    /* NEW RULES */
    /*
        1 - 3   == FAIL
        3 - 4.5 == AA Large
        4.5 - 7 == AA
        7 -     == AAA
    */
    const contrast = Math.round((1 / ratio + Number.EPSILON) * 100) / 100
    const score =
        contrast >= 7
            ? "AAA"
            : contrast >= 4.5
            ? "AA"
            : contrast >= 3
            ? "AA Large"
            : "Fail"

    return {
        aa: {
            large: ratio < 1 / 3 ? true : false,
            small: ratio < 1 / 2.5 ? true : false
        },
        aaa: {
            large: ratio < 1 / 4.5 ? true : false,
            small: ratio < 1 / 7 ? true : false
        },
        ratio,
        readableContrast: contrast,
        score,
        fail: contrast < 3
    }
}

export function colorContrast(colorOne, colorTwo) {
    const colorOneRgb = getRgb(colorOne)
    const colorTwoRgb = getRgb(colorTwo)
    const colorOneLum = luminance(colorOneRgb.r, colorOneRgb.g, colorOneRgb.b)
    const colorTwoLum = luminance(colorTwoRgb.r, colorTwoRgb.g, colorTwoRgb.b)
    const r = ratio(colorOneLum, colorTwoLum)
    return resultSet(r)
}
