import React from "react"
import styled from "styled-components/macro"

import Box from "./Box"

const Warning = ({ children, className }) => {
    return <StyledWarning className={className}>{children}</StyledWarning>
}

const StyledWarning = styled(Box)`
    background: ${(props) => props.theme.colors.failLight};

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span,
    p {
        font-size: 0.925rem;
        color: ${(props) => props.theme.colors.fail};
    }
    p {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
`

export default Warning
