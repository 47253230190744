import React, { useState, useEffect } from "react"
import serializeObjectToQueryString from "../utils/serializeObjectToQueryString"

const SharedLinkFetchAndRedirect = (props) => {
    const [
        isLoadingShareableLinkData,
        setIsLoadingShareableLinkData
    ] = useState(true)
    const [, setShareableLinkUnwrapped] = useState(null)

    const shareableLinkId = props.match.params.shareableLink

    useEffect(() => {
        async function fetchSharedLinkData(id) {
            fetch("/.netlify/functions/shortlink-read?id=" + shareableLinkId)
                .then((response) => {
                    if (response.status === 200) {
                        return response.json()
                    }
                    return Promise.reject(response.status)
                })
                .then((data) => {
                    const qs = serializeObjectToQueryString(data.data)
                    setShareableLinkUnwrapped(qs)
                    setIsLoadingShareableLinkData(false)
                    window.location.href = "/?" + qs
                })
                .catch((error) => {
                    // TODO show error message
                    console.error("fetchSharedLinkData error", error)
                })
        }
        if (shareableLinkId) {
            fetchSharedLinkData(shareableLinkId)
        }
    }, [shareableLinkId])

    return <>{isLoadingShareableLinkData && <h1>Loading...</h1>}</>
}

export default SharedLinkFetchAndRedirect
