import React from "react"
import styled from "styled-components/macro"

import { H2 } from "../components/Heading"
import { Reset } from "../assets/icons"

const BoxHeader = ({ children, onResetClick = null, as = "h2" }) => {
    return (
        <Horizontal>
            <H2 as={as}>{children}</H2>
            {onResetClick && (
                <ResetButton onClick={() => onResetClick()}>
                    Reset <Reset fill="#aa0808" />
                </ResetButton>
            )}
        </Horizontal>
    )
}

const Horizontal = styled.div`
    display: flex;
    justify-content: space-between;
`

const ResetButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: none;
    background: none;
    border: none;
    font-family: "Basier Circle Medium";
    font-weight: normal;
    font-size: 15px;
    color: ${(props) => props.theme.colors.fail};
    cursor: pointer;
    svg {
        margin-left: 4px;
    }
`

export default BoxHeader
