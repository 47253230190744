import * as React from "react"

function SvgWifi(props) {
    return (
        <svg width={16} height={12} fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.33 2.608a8.64 8.64 0 015.953 2.378.308.308 0 00.434-.003l1.156-1.167a.322.322 0 00-.003-.456 10.897 10.897 0 00-15.08 0 .322.322 0 00-.003.456l1.156 1.167c.12.12.313.121.434.003A8.641 8.641 0 018.33 2.608zm0 3.796c1.218 0 2.392.452 3.294 1.27a.31.31 0 00.434-.006L13.212 6.5a.322.322 0 00-.004-.46 7.16 7.16 0 00-9.753 0 .322.322 0 00-.004.46l1.154 1.167a.31.31 0 00.434.005 4.907 4.907 0 013.291-1.27zm2.313 2.553a.314.314 0 01-.094.23l-1.997 2.017a.312.312 0 01-.443 0L6.11 9.188a.314.314 0 01.01-.457 3.422 3.422 0 014.419 0c.064.058.102.14.103.226z"
                fill="#000"
            />
        </svg>
    )
}

export default SvgWifi
