import * as React from "react"

function SvgLogoSymbol(props) {
    return (
        <svg width={17} height={21} viewBox="0 0 17 21" fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 0H5L0 5v6h6l5-5h6V0zM6 15l5-5h6v6l-5 5H0v-6h6z"
                fill="#fff"
            />
        </svg>
    )
}

export default SvgLogoSymbol
