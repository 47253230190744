import * as React from "react"

function SvgRocket(props) {
    const size = props.size || 24
    const color = props.color || "#5A3ADF"
    return (
        <svg
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 48 48"
            {...props}
        >
            <path
                d="M41.84 4.768C41.714 4.736 38.728 4 34.992 4c-5.17 0-9.326 1.426-12.022 4.122a737.524 737.524 0 00-6.35 6.424c-2.428-.274-8.154-.406-12.034 3.476A1.991 1.991 0 004 19.436c0 .532.21 1.04.586 1.414l22.626 22.626c.376.376.882.586 1.414.586.532 0 1.04-.21 1.414-.586 3.908-3.906 3.788-9.628 3.534-12.02l6.368-6.366c6.382-6.382 3.48-18.364 3.354-18.87a1.998 1.998 0 00-1.456-1.452zm-4.726 17.496l-7.072 7.07a1.998 1.998 0 00-.546 1.806c.01.05.814 4.484-1.072 7.888L9.034 19.64c3.4-1.876 7.882-1.008 7.896-1.008.66.14 1.368-.072 1.846-.566.006-.006 2.812-2.906 7.024-7.118C28.236 8.512 32.142 8 34.992 8c1.894 0 3.586.222 4.664.406.488 2.862 1.25 10.064-2.542 13.858z"
                fill={color}
            />
            <path
                d="M31.456 20.608a4 4 0 100-8 4 4 0 000 8zM10 32c-4 2-4 10-4 10s6 0 10-4l-6-6z"
                fill={color}
            />
        </svg>
    )
}

export default SvgRocket
