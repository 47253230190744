import React from "react"
import styled from "styled-components/macro"
import Color from "../components/Color"
import { H3 } from "../components/Heading"
import AddButton from "../components/AddButton"

const SingleColor = ({
    title,
    colors,
    colorKey,
    addButtonText,
    onAddButtonClick
}) => {
    return (
        <ColorSectionContainer>
            <H3 as="h3">{title}</H3>
            {colors.map((color) => (
                <Color
                    colorKey={colorKey}
                    key={`${colorKey}-${color}`}
                    color={color}
                />
            ))}
            {addButtonText && (
                <AddButton text={addButtonText} onClick={onAddButtonClick} />
            )}
        </ColorSectionContainer>
    )
}

const ColorSectionContainer = styled.div`
    margin-bottom: 24px;
`

export default SingleColor
