import React, { useState } from "react"
import styled from "styled-components/macro"
import Box from "../../components/Box"

import Brand from "../../components/Brand"
import Paragraph from "../../components/Paragraph"

const EnterLivePreview = (props) => {
    const [enteredCode, setEnteredCode] = useState(props.prefilledCode || "")

    const handleEnteredCodeGoClick = () => {
        if (enteredCode && enteredCode.length === 6) {
            window.location.href = `/p/${enteredCode.toUpperCase()}?live=1`
        }
    }

    const handleOnKeyDown = (event) => {
        if (event.key === "Enter") {
            handleEnteredCodeGoClick()
        }
    }

    return (
        <EnterCodeWrapper>
            <Brand />
            <Box>
                <Paragraph>
                    Enter the code you've been given to see a live preview.
                </Paragraph>
                <Paragraph>
                    To see the preview in full screen, add this page to your
                    home screen and open it from there.
                </Paragraph>
                <CodeInput
                    placeholder="Enter the code..."
                    defaultValue={enteredCode}
                    onChange={(event) => {
                        setEnteredCode(event.target.value)
                    }}
                    onKeyDown={handleOnKeyDown}
                    maxLength="6"
                />
                <Button onClick={() => handleEnteredCodeGoClick()}>Go</Button>
            </Box>
        </EnterCodeWrapper>
    )
}

const EnterCodeWrapper = styled.div`
    flex-direction: column;
`

const CodeInput = styled.input`
    font-family: "Basier Circle";
    display: flex;
    align-items: flex-start;
    width: calc(100% - 32px);
    border: 1px solid ${(props) => props.theme.colors.gray5};
    border-radius: 8px;
    height: 50px;
    box-shadow: none;
    font-size: 16px;
    padding: 0 16px;
`

const Button = styled.button`
    font-family: "Basier Circle";
    background: ${(props) => props.theme.colors.blueMain};
    border-radius: 8px;
    width: 100%;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: ${(props) => props.theme.colors.white};
    border: none;
    padding: 16px 0;
    margin-top: 12px;
`

export default EnterLivePreview
