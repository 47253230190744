import React from "react"
import styled from "styled-components/macro"

const Bleed = ({ baseSpacing = 16, sticky = false, children, className }) => {
    return (
        <StyledBleed
            className={className}
            baseSpacing={baseSpacing}
            sticky={sticky}
        >
            {children}
        </StyledBleed>
    )
}

const StyledBleed = styled.div`
    /*width: calc(100% + ${(props) => props.baseSpacing * 2}px);
    margin-left: -${(props) => props.baseSpacing}px;*/
    width: 100%;
    margin-left: -${(props) => props.baseSpacing}px;
    padding: 0 ${(props) => props.baseSpacing}px;
    ${(props) => props.sticky && "position: sticky; top: 0;"}
`

export default Bleed
