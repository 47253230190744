const fetchGoogleFontCSS = async (family, weights) => {
    const properWeights = weights.map((weight) => {
        if (weight === "regular") {
            return "400"
        }
        return weight
    })
    try {
        const response = await fetch(
            `https://fonts.googleapis.com/css2?family=${family}:wght@${properWeights.join(
                ";"
            )}`
        )
        const textResponse = await response.text()
        return textResponse
    } catch (error) {
        console.error(error)
    }
}

export default fetchGoogleFontCSS
