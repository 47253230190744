import url from "./currentUrl"

export const MEDIUM = { MOBILE_APP: "Mobile app", WEBSITE: "Website" }
export const WEBSITE_SIZE = {
    DESKTOP: "Desktop",
    TABLET: "Tablet",
    MOBILE: "Mobile"
}
export const FONT_SERVICE = {
    GOOGLE_FONTS: "Google Fonts",
    LOCAL: "Local fonts"
}

export const PRODUCTION = !!url.hostname.includes("styletile")
export const SOCKET_ENDPOINT = !PRODUCTION
    ? `ws://${url.hostname}:3000`
    : `https://styletile-realtime.herokuapp.com`
