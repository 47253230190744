import styled from "styled-components/macro"

export const H1 = styled.h1`
    margin: 0;
    font-style: normal;
    font-family: "Basier Circle Medium";
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
    padding: 0 0 0 8px;
`

export const H2 = styled(H1)`
    padding: 0 0 8px 0;
`

export const H3 = styled(H1)`
    font-size: 15px;
    padding: 0 0 8px 0 !important;
`
