import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components/macro"
import Header from "../../../components/Preview/Header"
import Preview from "../../../components/Preview/Preview"
import { H1, H2, H4 } from "../../../components/Preview/Headings"
import { useSettingsState } from "../../../utils/settingsContext"
import Bleed from "../../../components/Preview/Bleed"
import Section from "../../../components/Preview/Section"

import Stock01 from "../../../assets/images/stock-01.jpg"
import Stock02 from "../../../assets/images/stock-02.jpg"
import Stock03 from "../../../assets/images/stock-03.jpg"
import Stock04 from "../../../assets/images/stock-04.jpg"
import Stock05 from "../../../assets/images/stock-05.jpg"

import { BodySmall } from "../../../components/Preview/BodyText"
import TabWrapper from "../../../components/Preview/TabWrapper"

const Discover = ({ liveSettings = null }) => {
    const theme = useContext(ThemeContext)

    let settings
    const settingsState = useSettingsState()

    if (liveSettings) {
        // console.log("Have props liveSettings", liveSettings)
        settings = liveSettings
    } else {
        // console.log("No settings by props, using state/context")
        settings = settingsState
    }

    const baseSpacing = 16

    const topics = [
        { title: "Tech", query: "tech" },
        { title: "Design", query: "design" },
        { title: "Politics", query: "politics" },
        { title: "Finance", query: "finance" },
        { title: "Cars", query: "cars" },
        { title: "Food", query: "food" },
        { title: "Entertainment", query: "entertainment" }
    ]

    const populars = [
        {
            title: "Nobels fredspris til Verdens matvareprogram",
            source: "Dagbladet",
            sourceUrl: "https://db.no",
            timeAgo: "2 minutter siden",
            image: Stock01,
            ingress:
                "Esse adipisci et sed aut assumenda corporis placeat corrupti. Maxime distinctio aut quidem quasi. Possimus et et aspernatur labore autem fuga totam."
        },
        {
            title: "Oslo Børs stiger fra start – nykommer stiger kraftig",
            source: "E24",
            sourceUrl: "https://db.no",
            timeAgo: "6 timer siden",
            image: Stock02,
            ingress:
                "Esse adipisci et sed aut assumenda corporis placeat corrupti. Maxime distinctio aut quidem quasi. Possimus et et aspernatur labore autem fuga totam."
        },
        {
            title: "Nå kommer skredet av superkjappe SSD-er for gaming",
            source: "ITavisen.no",
            sourceUrl: "https://db.no",
            timeAgo: "2 timer siden",
            image: Stock03,
            ingress:
                "Esse adipisci et sed aut assumenda corporis placeat corrupti. Maxime distinctio aut quidem quasi. Possimus et et aspernatur labore autem fuga totam."
        },
        {
            title:
                "Apple-lekkasje: Ny Iphone vil slå knockout på konkurrentene på pris",
            source: "Nettavisen",
            sourceUrl: "https://db.no",
            timeAgo: "5 timer siden",
            image: Stock04,
            ingress:
                "Esse adipisci et sed aut assumenda corporis placeat corrupti. Maxime distinctio aut quidem quasi. Possimus et et aspernatur labore autem fuga totam."
        },
        {
            title: "Slik ruster NRK Og TV 2 seg i kampen om julens klassikere",
            source: "VG",
            sourceUrl: "https://db.no",
            timeAgo: "3 timer siden",
            image: Stock05,
            ingress:
                "Esse adipisci et sed aut assumenda corporis placeat corrupti. Maxime distinctio aut quidem quasi. Possimus et et aspernatur labore autem fuga totam."
        }
    ]

    const onTopicClick = (topicQuery) => {}

    const getTopicButton = (topic, index) => {
        return (
            <Topic
                key={`topic-${index}`}
                className="apply-font-main"
                color={settings.textColor.color}
                onClick={() => {
                    onTopicClick(topic.query)
                }}
                baseSpacing={baseSpacing}
            >
                {topic.title}
            </Topic>
        )
    }

    const getTopicsScroller = () => {
        return (
            <TopicsWrapper baseSpacing={baseSpacing}>
                <Topics baseSpacing={baseSpacing}>
                    {topics.map((topic, index) => getTopicButton(topic, index))}
                </Topics>
            </TopicsWrapper>
        )
    }

    const getPopularCard = (popular, index) => {
        return (
            <Popular
                key={`popular-${index}`}
                className="apply-font-main"
                href={popular.sourceUrl}
                style={{ backgroundImage: `url(${popular.image})` }}
                baseSpacing={baseSpacing}
                onClick={(event) => {
                    event.preventDefault()
                    return false
                }}
            >
                <Overlay />
                <PopularContent>
                    <BodySmall className="apply-font-main">
                        {popular.source} • {popular.timeAgo}
                    </BodySmall>
                    <H2 className="apply-font-main apply-font-heading">
                        {popular.title}
                    </H2>
                    <BodySmall className="apply-font-main">
                        {popular.ingress}
                    </BodySmall>
                </PopularContent>
            </Popular>
        )
    }

    const getPopularScroller = () => {
        return (
            <PopularWrapper baseSpacing={baseSpacing}>
                <Populars>
                    {populars.map((popular, index) =>
                        getPopularCard(popular, index)
                    )}
                </Populars>
            </PopularWrapper>
        )
    }

    return (
        <TabWrapper isLivePreview={!!liveSettings}>
            <Preview
                className="preview-mobile"
                baseSpacing={baseSpacing}
                bgColor={theme.colors.gray7}
            >
                <Bleed sticky baseSpacing={baseSpacing}>
                    <Header baseSpacing={baseSpacing}>
                        <H1
                            className="apply-font-main apply-font-heading"
                            textColor={settings.textColor.color}
                        >
                            Discover
                        </H1>
                        <Input
                            type="search"
                            placeholder="Search for topics or headlines"
                            className="apply-font-main"
                        />
                    </Header>
                </Bleed>

                <Bleed baseSpacing={baseSpacing}>
                    <Section baseSpacing={baseSpacing} first>
                        <H4
                            className="apply-font-main apply-font-heading"
                            textColor={settings.textColor.color}
                        >
                            Topics
                        </H4>
                        {getTopicsScroller()}
                        <H4
                            className="apply-font-main apply-font-heading"
                            textColor={settings.textColor.color}
                        >
                            Popular now
                        </H4>
                        {getPopularScroller()}
                    </Section>
                </Bleed>
            </Preview>
        </TabWrapper>
    )
}

const Input = styled.input`
    height: 52px;
    min-height: 52px;
    border-radius: 26px;
    font-family: Inter;
    font-style: normal;
    border: 1px solid #d3d6dd;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 17px;
    flex: 1;
    width: 100%;
    margin: 16px 0 0;
    box-shadow: none;
    color: ${(props) => props.theme.colors.gray3};
    &:focus {
        outline: none;
        border-color: ${(props) => props.theme.colors.black};
        color: ${(props) => props.theme.colors.gray1};
    }
    -webkit-appearance: none;
`

const TopicsWrapper = styled.div`
    display: flex;
    overflow-x: scroll;
    margin-left: -${(props) => props.baseSpacing}px;
    margin-right: -${(props) => props.baseSpacing}px;
    margin-bottom: ${(props) => props.baseSpacing}px;
    padding: 0 ${(props) => props.baseSpacing}px 5px;
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    ::-webkit-scrollbar {
        width: 0px; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
    }
`

const Topics = styled.div`
    display: flex;
    flex-wrap: nowrap;
    padding-right: ${(props) => props.baseSpacing}px;
`

const Topic = styled.button`
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(6, 28, 38, 0.1);
    border-radius: 8px;
    border: none;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    padding: ${(props) => props.baseSpacing}px 24px;
    margin-right: 8px;
    color: ${(props) => props.color};
`

const PopularWrapper = styled.div`
    display: flex;
    overflow-x: scroll;
    margin-left: -${(props) => props.baseSpacing}px;
    margin-right: -${(props) => props.baseSpacing}px;
    padding: 0 ${(props) => props.baseSpacing}px 5px;
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    ::-webkit-scrollbar {
        width: 0px; /* Remove scrollbar space */
        background: transparent; /* Optional: just make scrollbar invisible */
    }
`

const Populars = styled.div`
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
`

const Popular = styled.a`
    box-shadow: 0px 2px 5px rgba(6, 28, 38, 0.1);
    border-radius: 8px;
    position: relative;
    text-decoration: none;
    color: #ffffff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow: hidden;
    display: flex;
    padding: ${(props) => props.baseSpacing}px;
    width: 80%;
    height: auto;
    display: flex;
    flex: 1 0 auto;
    height: 310px;
    margin-right: 8px;
    &:last-child {
        margin-right: ${(props) => props.baseSpacing}px;
    }
`

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    z-index: 1024;
`

const PopularContent = styled.div`
    z-index: 1048;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
`

export default Discover
