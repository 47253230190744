import React, { useState } from "react"

import { FONT_SERVICE } from "../utils/constants"

import { H3 } from "../components/Heading"
import BoxHeader from "../components/BoxHeader"
import Switcher from "../components/Switcher"
import FontSelector from "../components/FontSelector"
import AddButton from "../components/AddButton"

import { useSettingsState } from "../utils/settingsContext"

const FontSection = (props) => {
    const settingsState = useSettingsState()

    const [fontSource, setFontSource] = useState(FONT_SERVICE.GOOGLE_FONTS)

    const [
        isAddHeadingFontSectionVisible,
        setIsAddHeadingFontSectionVisible
    ] = useState(!!settingsState.fonts.heading.family)

    /*
    const resetFonts = () => {
        console.log("resetFonts")
    }
    */

    return (
        <>
            <BoxHeader /* onResetClick={resetFonts} */>Fonts</BoxHeader>
            <Switcher
                options={[
                    { title: FONT_SERVICE.GOOGLE_FONTS },
                    { title: FONT_SERVICE.LOCAL }
                ]}
                onChange={(fontSource) => setFontSource(fontSource)}
            />
            {fontSource && (
                <>
                    <H3 as="h3" style={{ marginTop: 24 }}>
                        Main font
                    </H3>
                    <FontSelector fontSource={fontSource} pickerId="main" />
                    {!isAddHeadingFontSectionVisible && (
                        <AddButton
                            text={"Add heading font"}
                            onClick={() =>
                                setIsAddHeadingFontSectionVisible(true)
                            }
                        />
                    )}
                    {isAddHeadingFontSectionVisible && (
                        <>
                            <H3
                                as="h3"
                                style={{
                                    marginTop: 24,
                                    display: "flex",
                                    justifyContent: "space-between"
                                }}
                            >
                                Heading font
                            </H3>
                            <FontSelector
                                fontSource={fontSource}
                                pickerId="heading"
                                onRemoveClick={() => {
                                    setIsAddHeadingFontSectionVisible(false)
                                }}
                            />
                        </>
                    )}
                </>
            )}
        </>
    )
}

export default FontSection
