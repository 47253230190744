import React, { useContext, useState } from "react"
import styled, { ThemeContext } from "styled-components/macro"
import Preview from "../../../components/Preview/Preview"
import { H2, H3, H4 } from "../../../components/Preview/Headings"
import { useSettingsState } from "../../../utils/settingsContext"
import Bleed from "../../../components/Preview/Bleed"
import TabWrapper from "../../../components/Preview/TabWrapper"

import Portrait from "../../../assets/images/portrait-01.jpg"
import { BodySmall } from "../../../components/Preview/BodyText"
import { Camera, Check } from "../../../assets/icons"

const Profile = ({ liveSettings = null }) => {
    const theme = useContext(ThemeContext)

    const baseSpacing = 16

    let settings
    const settingsState = useSettingsState()

    if (liveSettings) {
        // console.log("Have props liveSettings", liveSettings)
        settings = liveSettings
    } else {
        // console.log("No settings by props, using state/context")
        settings = settingsState
    }

    const [pills, setPills] = useState([
        { name: "Tech", isActive: true },
        { name: "Design", isActive: true },
        { name: "Politics", isActive: false },
        { name: "Cars", isActive: false },
        { name: "Food", isActive: false },
        { name: "Entertainment", isActive: true },
        { name: "Finance", isActive: true }
    ])

    const handlePillClick = (index) => {
        const thePill = pills[index]
        const newPills = [...pills]
        newPills[index] = { name: thePill.name, isActive: !thePill.isActive }
        setPills(newPills)
    }

    return (
        <TabWrapper isLivePreview={!!liveSettings}>
            <Preview
                className="preview-mobile"
                baseSpacing={baseSpacing}
                bgColor={theme.colors.gray7}
            >
                <BleedFullHeight baseSpacing={baseSpacing}>
                    <Centered>
                        <PortraitWrapper>
                            <PortraitImage src={Portrait} />
                            <IconWrapper>
                                <Camera color={settings.accentColor.color} />
                            </IconWrapper>
                        </PortraitWrapper>
                        <H2
                            className="apply-font-main apply-font-heading"
                            textColor={settings.textColor.color}
                        >
                            Jim T. Bamble
                        </H2>
                        <BodySmall
                            className="apply-font-main"
                            textColor={settings.textColor.color}
                        >
                            Member since 2018
                        </BodySmall>
                        <KeyFigures>
                            <Figure>
                                <H3
                                    className="apply-font-main apply-font-heading"
                                    textColor={settings.textColor.color}
                                >
                                    349
                                </H3>
                                <BodySmall
                                    className="apply-font-main"
                                    textColor={settings.textColor.color}
                                >
                                    Followers
                                </BodySmall>
                            </Figure>
                            <Figure>
                                <H3
                                    className="apply-font-main apply-font-heading"
                                    textColor={settings.textColor.color}
                                >
                                    160
                                </H3>
                                <BodySmall
                                    className="apply-font-main"
                                    textColor={settings.textColor.color}
                                >
                                    Following
                                </BodySmall>
                            </Figure>
                            <Figure>
                                <H3
                                    className="apply-font-main apply-font-heading"
                                    textColor={settings.textColor.color}
                                >
                                    402
                                </H3>
                                <BodySmall
                                    className="apply-font-main"
                                    textColor={settings.textColor.color}
                                >
                                    Favorited
                                </BodySmall>
                            </Figure>
                        </KeyFigures>
                    </Centered>
                    <Card>
                        <H4
                            className="apply-font-main apply-font-heading"
                            textColor={settings.textColor.color}
                        >
                            My topics
                        </H4>
                        <Pills>
                            {pills.map((pill, index) => (
                                <Pill
                                    key={"pill-" + index}
                                    accentColor={settings.accentColor}
                                    className={`${
                                        pill.isActive ? "active" : ""
                                    } apply-font-main family-only`}
                                    onClick={() => handlePillClick(index)}
                                >
                                    {pill.name}
                                    <CheckWrapper isActive={pill.isActive}>
                                        <Check
                                            size={18}
                                            color={
                                                settings.accentColor
                                                    .contrastColor
                                            }
                                        />
                                    </CheckWrapper>
                                </Pill>
                            ))}
                        </Pills>
                    </Card>
                </BleedFullHeight>
            </Preview>
        </TabWrapper>
    )
}

const BleedFullHeight = styled(Bleed)`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const Centered = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const PortraitImage = styled.img`
    border-radius: 50%;
    width: 120px;
    height: 120px;
`

const PortraitWrapper = styled.div`
    position: relative;
    margin: 48px auto 16px;
`

const IconWrapper = styled.button`
    border: none;
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 2px 5px rgba(6, 28, 38, 0.1);
    width: 40px;
    height: 40px;
    display: flex;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
`

const KeyFigures = styled.div`
    display: flex;
    justify-content: space-around;
    margin: 36px 32px 0;
    align-self: stretch;
`

const Figure = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`

const Card = styled.div`
    background-color: ${(props) => props.theme.colors.white};
    border-top-left-radius: 32px;
    border-top-right-radius: 32px;
    margin-top: 36px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    flex: 1;
`

const Pills = styled.div`
    display: flex;
    flex-wrap: wrap;
`

const Pill = styled.span`
    background-color: ${(props) => props.accentColor.color + "0d"};
    color: ${(props) => props.accentColor.color};
    font-weight: 600;
    font-size: 13px;
    line-height: 15.6px;
    padding-right: 23px;
    padding-left: 23px;
    margin: 4px 8px 4px 0;
    border-radius: 45px;
    height: 32px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all 175ms ease-in-out;
    &.active {
        background-color: ${(props) => props.accentColor.color};
        color: ${(props) => props.accentColor.contrastColor};
        padding-right: 12px;
        padding-left: 16px;
    }
`

const CheckWrapper = styled.span`
    width: ${(props) => (props.isActive ? "18px" : 0)};
    opacity: ${(props) => (props.isActive ? 1 : 0)};
    transition: all 175ms ease-in-out;
    svg {
        margin-top: 2px;
        margin-left: 2px;
    }
`

export default Profile
