import React, { useState, useEffect } from "react"
import styled from "styled-components/macro"

import { colorContrast } from "../utils/colorFunctions"

const ContrastIndicator = ({
    color,
    onClick,
    customContrastColor,
    asHeading = false
}) => {
    const [, setTextColor] = useState(null)
    const [contrasts, setContrasts] = useState({
        color,
        white: {},
        black: {},
        custom: {}
    })
    const [currentContrast, setCurrentContrast] = useState(null)

    const setupAutomatic = () => {
        // Check if we should use white or black text
        const withWhiteText = colorContrast(color, "#FFFFFF")
        const withBlackText = colorContrast(color, "#000000")
        setContrasts({
            ...contrasts,
            white: withWhiteText,
            black: withBlackText
        })
        const textColor =
            withBlackText.ratio > withWhiteText.ratio ? "#FFFFFF" : "#000000"
        setTextColor(textColor)
        setCurrentContrast(
            withBlackText.ratio > withWhiteText.ratio
                ? withWhiteText
                : withBlackText
        )
    }

    const setupWithCustomContrastColor = () => {
        const withCustomText = colorContrast(color, customContrastColor)
        setContrasts({
            ...contrasts,
            custom: withCustomText
        })
        setCurrentContrast(withCustomText)
        setTextColor(customContrastColor)
    }

    useEffect(() => {
        if (customContrastColor) {
            setupWithCustomContrastColor()
        } else {
            setupAutomatic()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [color, customContrastColor])

    const renderSummary = () => {
        if (currentContrast) {
            return (
                <StyledContrastIndicator
                    fail={currentContrast.fail}
                    asHeading={asHeading}
                >
                    {asHeading && `${currentContrast.readableContrast}:1`}{" "}
                    {currentContrast.score}
                </StyledContrastIndicator>
            )
        }
    }

    return <div onClick={onClick}>{renderSummary()}</div>
}

const StyledContrastIndicator = styled.div`
    border: ${(props) => (props.asHeading ? "none" : "1px solid #ffffff")};
    background: ${(props) =>
        props.asHeading
            ? "transparent"
            : props.fail
            ? props.theme.colors.failLight
            : props.theme.colors.successLight};
    color: ${(props) =>
        props.fail ? props.theme.colors.fail : props.theme.colors.success};
    border-radius: 4px;
    font-family: "Basier Circle Medium";
    font-weight: normal;
    font-size: ${(props) => (props.asHeading ? "34px" : "15px")};
    padding: ${(props) => (props.asHeading ? "8px 0 0" : "4px")};
    cursor: pointer;
`

export default ContrastIndicator
