const theme = {
    colors: {
        blueMain: "#222E46",
        blueLight: "#E7EDFB",
        black: "#0D1930",
        gray1: "#222E46",
        gray2: "#49546A",
        gray3: "#818A9C",
        gray4: "#BBC0C9",
        gray5: "#D3D6DD",
        gray6: "#E9EBEF",
        gray7: "#F5F6F9",
        white: "#FFFFFF",
        success: "#038153",
        successLight: "linear-gradient(0deg, #E6F2EE, #E6F2EE), #FFFFFF",
        fail: "#AA0808",
        failLight: "linear-gradient(0deg, #F7E6E6, #F7E6E6), #FFFFFF"
    }
}

export default theme
