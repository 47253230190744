import React, { useState } from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { ThemeProvider } from "styled-components/macro"

import "./App.css"
import theme from "./assets/themes/default"

import Sidebar from "./containers/Sidebar"
import Preview from "./containers/Preview"

import { MEDIUM, WEBSITE_SIZE } from "./utils/constants"
import { SettingsProvider } from "./utils/settingsContext"
import SharedLinkFetchAndRedirect from "./components/SharedLinkFetchAndRedirect"
import LivePreview from "./containers/LivePreview/LivePreview"
import EnterLivePreview from "./containers/LivePreview/EnterLivePreview"

function App() {
    const [previewMedium, setPreviewMedium] = useState(MEDIUM.MOBILE_APP)
    const [previewWebsiteWidth, setPreviewWebsiteWidth] = useState(
        WEBSITE_SIZE.DESKTOP
    )

    const forceWidth = (width) => {
        setPreviewWebsiteWidth(width)
    }

    return (
        <SettingsProvider>
            <ThemeProvider theme={theme}>
                <div className="App">
                    <Router>
                        <Switch>
                            <Route
                                exact
                                path="/:shareableLink([0-9A-Z]{6})"
                                component={SharedLinkFetchAndRedirect}
                            />
                            <Route exact path="/">
                                <Sidebar
                                    previewMedium={previewMedium}
                                    previewWebsiteWidth={previewWebsiteWidth}
                                    onPreviewSetupChange={(setup) => {
                                        setPreviewMedium(setup.previewMedium)
                                        setPreviewWebsiteWidth(
                                            setup.previewWebsiteWidth
                                        )
                                    }}
                                />
                                <Preview
                                    previewMedium={previewMedium}
                                    previewWebsiteWidth={previewWebsiteWidth}
                                    forceWidth={(width) => forceWidth(width)}
                                />
                            </Route>
                            <Route
                                exact
                                path="/p/:livePreviewCode([0-9A-Z]{6})?"
                                component={LivePreview}
                            />
                            <Route path="/p">
                                <EnterLivePreview />
                            </Route>
                        </Switch>
                    </Router>
                </div>
            </ThemeProvider>
        </SettingsProvider>
    )
}

export default App
