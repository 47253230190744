import * as React from "react"

function SvgMobileAlt({ size = 24, color = "#0B4FD5" }) {
    return (
        <svg width={size} height={size} fill="none">
            <path
                d="M16.75 2h-10c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2zm-10 18V4h10l.002 16H6.75z"
                fill={color}
            />
            <path d="M11.75 19a1 1 0 100-2 1 1 0 000 2z" fill={color} />
        </svg>
    )
}

export default SvgMobileAlt
