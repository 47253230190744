import * as React from "react"

function SvgReset(props) {
    const fill = props.fill || "#0D1930"
    return (
        <svg width={24} height={24} fill="none" {...props}>
            <path
                d="M12 16c1.671 0 3-1.331 3-3s-1.329-3-3-3-3 1.331-3 3 1.329 3 3 3z"
                fill={fill}
            />
            <path
                d="M20.817 11.186a8.94 8.94 0 00-1.355-3.219 9.053 9.053 0 00-2.43-2.43 8.95 8.95 0 00-3.219-1.355 9.028 9.028 0 00-1.838-.18V2L8 5l3.975 3V6.002c.484-.002.968.044 1.435.14a6.961 6.961 0 012.502 1.053 7.005 7.005 0 011.892 1.892A6.967 6.967 0 0119 13a7.032 7.032 0 01-.55 2.725 7.11 7.11 0 01-.644 1.188 7.2 7.2 0 01-.858 1.039 7.028 7.028 0 01-3.536 1.907 7.13 7.13 0 01-2.822 0 6.961 6.961 0 01-2.503-1.054 7.002 7.002 0 01-1.89-1.89A6.996 6.996 0 015 13H3a9.02 9.02 0 001.539 5.034 9.096 9.096 0 002.428 2.428A8.95 8.95 0 0012 22a9.09 9.09 0 001.814-.183 9.014 9.014 0 003.218-1.355 8.886 8.886 0 001.331-1.099 9.228 9.228 0 001.1-1.332A8.952 8.952 0 0021 13a9.09 9.09 0 00-.183-1.814z"
                fill={fill}
            />
        </svg>
    )
}

export default SvgReset
