import * as React from "react"

function SvgCreditCard({ color }) {
    return (
        <svg width={24} height={24} fill="none">
            <path
                d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zM4 6h16v2H4V6zm0 12v-6h16.001l.001 6H4z"
                fill={color}
            />
            <path d="M6 14h6v2H6v-2z" fill={color} />
        </svg>
    )
}

export default SvgCreditCard
