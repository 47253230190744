import React from "react"
import styled from "styled-components/macro"

export const H1 = ({ textColor, className, children }) => {
    return (
        <StyledH1 className={className} textColor={textColor}>
            {children}
        </StyledH1>
    )
}

const StyledH1 = styled.h1`
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 36px;
    color: ${(props) => props.textColor};
    margin: 0 0 8px 0;
`

export const H2 = ({ textColor, className, children }) => {
    return (
        <StyledH2 className={className} textColor={textColor}>
            {children}
        </StyledH2>
    )
}

const StyledH2 = styled.h2`
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 26.4px;
    color: ${(props) => props.textColor};
    margin: 0 0 8px 0;
`

export const H3 = ({ textColor, className, children }) => {
    return (
        <StyledH3 className={className} textColor={textColor}>
            {children}
        </StyledH3>
    )
}

const StyledH3 = styled.h3`
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 20.4px;
    color: ${(props) => props.textColor};
    margin: 0 0 8px 0;
`

export const H4 = ({ textColor, className, children }) => {
    return (
        <StyledH4 className={className} textColor={textColor}>
            {children}
        </StyledH4>
    )
}

const StyledH4 = styled.h4`
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15.6px;
    color: ${(props) => props.textColor};
    margin: 0 0 8px 0;
    letter-spacing: 0.5px;
    text-transform: uppercase;
`
