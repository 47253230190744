/* Create a query parameter string (recursive) with '.' as separator for nested object keys */
const serializeObjectToQueryString = (obj, prefix) => {
    var str = [],
        p
    for (p in obj) {
        if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + "." + p : p,
                v = obj[p]
            if (k !== "query") {
                str.push(
                    v !== null && typeof v === "object"
                        ? serializeObjectToQueryString(v, k)
                        : encodeURIComponent(k) + "=" + encodeURIComponent(v)
                )
            }
        }
    }
    return str.join("&")
}

export default serializeObjectToQueryString
