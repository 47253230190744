import React, { useState, useEffect } from "react"

import EnterLivePreview from "./EnterLivePreview"

import Waiting from "./Waiting"
import AppPreview from "../Previews/Mobile/AppPreview"

import {
    initiateSocket,
    disconnectSocket,
    loadInitialSettings,
    subscribeToSettingsUpdates,
    requestSettings
} from "../../utils/previewSocketFunctions"
import fetchGoogleFontCSS from "../../utils/fetchGoogleFontCSS"

const WebFont = require("webfontloader")

const LivePreview = (props) => {
    const livePreviewCode = props.match.params.livePreviewCode
    const searchParams = new URLSearchParams(window.location.search)
    const liveMode =
        searchParams.has("live") && searchParams.get("live") === "1"
    const inAppPreview =
        searchParams.has("app") && searchParams.get("app") === "1"

    const [settings, setSettings] = useState(null)

    const [didReceiveSettings, setDidReceiveSettings] = useState(false)

    const [mainFontFamily, setMainFontFamily] = useState("")
    const [headingFontFamily, setHeadingFontFamily] = useState("")

    const [mainFontWeightCSS, setMainFontWeightCSS] = useState("")
    const [headingFontWeightCSS, setHeadingFontWeightCSS] = useState("")

    useEffect(() => {
        if (livePreviewCode) {
            initiateSocket({
                room: livePreviewCode,
                create: false
            })
            subscribeToSettingsUpdates((error, update) => {
                if (error) {
                    return
                }
                setDidReceiveSettings(true)

                // TODO check why this sometimes nests from backend
                if (update.settings.accentColor) {
                    setSettings(update.settings)
                } else {
                    setSettings(update.settings.settings)
                }
            })
        }
        return () => {
            disconnectSocket()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [livePreviewCode])

    useEffect(() => {
        loadInitialSettings((error, initialSettings) => {
            console.log("initialSettings", initialSettings.initialSettings)
            setSettings(initialSettings.initialSettings)
            setDidReceiveSettings(true)
        })
        if (livePreviewCode) {
            requestSettings(livePreviewCode)
        }
    }, [livePreviewCode])

    useEffect(() => {
        async function handleSettingsChange() {
            if (settings.fonts?.main?.family) {
                setMainFontFamily(settings.fonts.main.family)

                // Add CSS for the font weights (as this doesn't seem to entirely work with font-picker-react)
                const css = await fetchGoogleFontCSS(
                    settings.fonts.main.family,
                    [settings.fonts.main.weight]
                )
                setMainFontWeightCSS(css)

                WebFont.load({
                    google: {
                        families: [settings.fonts.main.family]
                    }
                })
            }
            if (settings.fonts?.heading?.family) {
                setHeadingFontFamily(settings.fonts.heading.family)

                // Add CSS for the font weights (as this doesn't seem to entirely work with font-picker-react)
                const css = await fetchGoogleFontCSS(
                    settings.fonts.heading.family,
                    [settings.fonts.heading.weight]
                )
                setHeadingFontWeightCSS(css)

                WebFont.load({
                    google: {
                        families: [settings.fonts.heading.family]
                    }
                })
            }
        }
        if (settings) {
            handleSettingsChange()
        }
    }, [settings])

    useEffect(() => {
        setTimeout(() => {
            requestSettings(livePreviewCode)
        }, 1000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (!liveMode) {
        return <EnterLivePreview prefilledCode={livePreviewCode} />
    }

    if (!didReceiveSettings) {
        return <Waiting />
    }
    return (
        <>
            {mainFontFamily && (
                <>
                    <style>{mainFontWeightCSS}</style>
                    <style>
                        {`.apply-font-main { 
                                font-family: "${mainFontFamily}"; 
                                font-weight: ${
                                    settings?.fonts?.main?.weight === "regular"
                                        ? "400"
                                        : settings?.fonts?.main?.weight || "400"
                                }; /* Converting "API" from Google to "400" which browser understands */ }`}
                    </style>
                </>
            )}
            {headingFontFamily && (
                <>
                    <style>{headingFontWeightCSS}</style>
                    <style>
                        {`.apply-font-heading { 
                                font-family: "${headingFontFamily}"; 
                                font-weight: ${
                                    settings?.fonts?.heading?.weight ===
                                    "regular"
                                        ? "400"
                                        : settings?.fonts?.heading?.weight ||
                                          "400"
                                }; /* Converting "API" from Google to "400" which browser understands */ }`}
                    </style>
                </>
            )}
            <AppPreview
                liveMode={true}
                liveSettings={settings}
                inAppPreview={inAppPreview}
            />
        </>
    )
}

export default LivePreview
