import React from "react"
import styled from "styled-components/macro"

const Box = ({ className, children }) => {
    return <BoxStyled className={className}>{children}</BoxStyled>
}

const BoxStyled = styled.div`
    padding: 32px 24px;
    margin: 0 0 8px 0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
`

export default Box
