import * as React from "react"

function SvgSwitchOn({ color }) {
    return (
        <svg width={50} height={28} fill="none">
            <rect
                x={1.5}
                y={1.5}
                width={47}
                height={25}
                rx={12.5}
                fill={color}
                stroke={color}
                strokeWidth={3}
            />
            <circle cx={36} cy={14} r={12} fill="#FFFFFA" />
        </svg>
    )
}

export default SvgSwitchOn
