import React, { useState, useEffect } from "react"
import styled from "styled-components/macro"

import { colorContrast } from "../utils/colorFunctions"
import ColorPicker from "../components/ColorPicker"
import ContrastIndicator from "../components/ContrastIndicator"
import ContrastTool from "../components/ContrastTool"
import { useSettingsDispatch } from "../utils/settingsContext"

import useDebounce from "../utils/useDebounce"

const Color = ({ color, colorKey }) => {
    const [textColor, setTextColor] = useState(null)
    const [selectedColor, setSelectedColor] = useState(color)
    const [isColorPickerVisible, setIsColorPickerVisible] = useState(false)
    const [isContrastToolVisible, setIsContrastToolVisible] = useState(false)

    const [contrasts, setContrasts] = useState({
        white: {},
        black: {},
        custom: {}
    })

    const [customContrastColor, setCustomContrastColor] = useState(null)

    const settingsDispatch = useSettingsDispatch()
    const updateURLQuery = useDebounce(
        () =>
            settingsDispatch({
                type: "UPDATE_URL_QUERY"
            }),
        200
    )

    const setupAutomatic = () => {
        setCustomContrastColor(null)

        // Check if we should use white or black text
        const withWhiteText = colorContrast(selectedColor, "#FFFFFF")
        const withBlackText = colorContrast(selectedColor, "#000000")
        setContrasts({
            ...contrasts,
            white: withWhiteText,
            black: withBlackText
        })
        const textColor =
            withBlackText.ratio > withWhiteText.ratio ? "#FFFFFF" : "#000000"
        setTextColor(textColor)

        settingsDispatch({
            type: "SET_COLORS",
            colors: {
                [colorKey]: {
                    color: selectedColor,
                    contrastColor: textColor
                }
            }
        })

        updateURLQuery()
    }

    const setupWithCustomContrastColor = () => {
        const withCustomText = colorContrast(selectedColor, customContrastColor)
        setContrasts({
            ...contrasts,
            custom: withCustomText
        })
        setTextColor(customContrastColor)

        settingsDispatch({
            type: "SET_COLORS",
            colors: {
                [colorKey]: {
                    color: selectedColor,
                    contrastColor: customContrastColor
                }
            }
        })

        updateURLQuery()
    }

    useEffect(() => {
        if (selectedColor || customContrastColor) {
            if (customContrastColor) {
                setupWithCustomContrastColor()
            } else {
                setupAutomatic()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedColor, customContrastColor])

    const handleCustomContrastColorChange = (customContrastColor) => {
        setCustomContrastColor(customContrastColor)
    }

    return (
        <>
            <StyledColor color={selectedColor} textColor={textColor}>
                <p
                    onClick={() => {
                        setIsContrastToolVisible(false)
                        setIsColorPickerVisible(!isColorPickerVisible)
                    }}
                >
                    {selectedColor.toUpperCase()}
                </p>
                <ContrastIndicator
                    color={selectedColor}
                    customContrastColor={
                        colorKey === "textColor"
                            ? "#FFFFFF"
                            : customContrastColor
                    }
                    onClick={() => {
                        setIsColorPickerVisible(false)
                        setIsContrastToolVisible(!isContrastToolVisible)
                    }}
                />
            </StyledColor>
            {isColorPickerVisible && (
                <ColorPicker
                    hexCode={selectedColor || "#aaa"}
                    onChange={(newColor) => {
                        setSelectedColor(newColor.hex)
                    }}
                />
            )}
            <ContrastTool
                visible={isContrastToolVisible}
                onCustomContrastColorChange={handleCustomContrastColorChange}
                color={selectedColor}
                customContrastColor={
                    colorKey === "textColor" ? "#FFFFFF" : customContrastColor
                }
                allowChange={colorKey === "textColor" ? false : true}
            />
        </>
    )
}

const StyledColor = styled.div.attrs((props) => ({
    style: {
        backgroundColor: props.color,
        color: props.textColor
    }
}))`
    border-radius: 8px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 8px;
    border: 1px solid ${(props) => props.theme.colors.gray6};
    p {
        cursor: pointer;
        margin: 0;
        display: flex;
        flex: 1;
        margin-right: 20px;
        padding: 18px 16px;
    }
`

export default Color
