import React, { useState } from "react"
import { Link } from "../assets/icons"
import BoxHeader from "../components/BoxHeader"
import CopyShareURLButton from "../components/CopyShareURLButton"
import ShareButton from "../components/ShareButton"

import { useSettingsState } from "../utils/settingsContext"

const ShareSection = () => {
    const [generatedShareableLink, setGeneratedShareableLink] = useState(null)

    const setupShareableLink = (shareableLinkResponse) => {
        const generatedLinksJSONString = localStorage.getItem(
            "styletile.generatedLinks"
        )
        if (!generatedLinksJSONString) {
            localStorage.setItem(
                "styletile.generatedLinks",
                JSON.stringify([
                    {
                        id: shareableLinkResponse.data.id,
                        ref: shareableLinkResponse.ref["@ref"].id
                    }
                ])
            )
        } else {
            const generatedLinks = JSON.parse(generatedLinksJSONString)
            generatedLinks.push({
                id: shareableLinkResponse.data.id,
                ref: shareableLinkResponse.ref["@ref"].id
            })
            localStorage.setItem(
                "styletile.generatedLinks",
                JSON.stringify(generatedLinks)
            )
        }
        setGeneratedShareableLink(
            `https://styletile.io/${shareableLinkResponse.data.id}`
        )
    }

    const settingsState = useSettingsState()
    const createShareableLink = () => {
        fetch("/.netlify/functions/shortlink-create", {
            body: JSON.stringify(settingsState),
            method: "POST"
        })
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                setupShareableLink(data)
            })
    }

    return (
        <>
            <BoxHeader>Share</BoxHeader>
            {!generatedShareableLink && (
                <ShareButton
                    text="Create shareable link"
                    onClick={createShareableLink}
                    icon={<Link />}
                />
            )}
            {generatedShareableLink && (
                <CopyShareURLButton url={generatedShareableLink} />
            )}
        </>
    )
}

export default ShareSection
