import * as React from "react"

function SvgCheck({ color = "#0D1930", size = 24 }) {
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none">
            <path
                d="M10 15.586l-3.293-3.293-1.414 1.414L10 18.414l9.707-9.707-1.414-1.414L10 15.586z"
                fill={color}
            />
        </svg>
    )
}

export default SvgCheck
