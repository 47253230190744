import defaultColors from "./colorDefaults"

var nest = function (obj, keys, v) {
    if (keys.length === 1) {
        obj[keys[0]] = v === "null" ? null : v
    } else {
        var key = keys.shift()
        obj[key] = nest(
            typeof obj[key] === "undefined" ? {} : obj[key],
            keys,
            v
        )
    }

    return obj
}

const unserializeURLSearchParams = (str, keySeparator = ".") => {
    const params = new URLSearchParams(str)
    let paramsObj = {}
    params.forEach((value, key) => {
        paramsObj = nest(paramsObj, key.split(keySeparator), value)
    })
    return paramsObj
}

function defaultSettings(override) {
    const query = window.location.search
    const paramsObj = unserializeURLSearchParams(query)

    return {
        accentColor: paramsObj.accentColor || {
            color: defaultColors().accentColor.color, // "#5A3ADF",
            contrastColor: defaultColors().accentColor.contrastColor // "#FFFFFF"
        },
        textColor: paramsObj.textColor || {
            color: defaultColors().textColor.color, // "#343434",
            contrastColor: defaultColors().textColor.contrastColor // "#FFFFFF"
        },
        uiColors: paramsObj.uiColors || {}, // TODO this won't work as is.. but it's not used (yet)
        fonts: {
            main: (paramsObj.fonts && paramsObj.fonts.main) || {
                family: "Inter",
                weight: "regular"
            },
            heading: (paramsObj.fonts && paramsObj.fonts.heading) || {
                family: null,
                weight: "regular"
            }
        },
        appBackgroundColor: "#FFFFFF"
    }
}

export default defaultSettings
