import React, { useState, useContext } from "react"
import styled, { ThemeContext } from "styled-components/macro"

import { useSettingsState } from "../../../utils/settingsContext"

import { Home, Compass, User, SliderAlt } from "../../../assets/icons"
import { SCREEN } from "../../../utils/enums"

const NavigationBar = ({
    liveSettings = null,
    onNavigation = null,
    inAppPreview = false,
    inDeviceSimulator = false,
    selectedScreen = SCREEN.HOME
}) => {
    const [currentScreen, setCurrentScreen] = useState(selectedScreen)

    const theme = useContext(ThemeContext)

    let settings
    const settingsState = useSettingsState()

    if (liveSettings) {
        // console.log("Have props settings", liveSettings)
        settings = liveSettings
    } else {
        // console.log("No settings by props, using state/context")
        settings = settingsState
    }

    const handleOnClick = (screen) => {
        setCurrentScreen(screen)
        onNavigation(screen)
    }

    return (
        <MenuBar
            isLivePreview={!!liveSettings}
            inAppPreview={inAppPreview}
            inDeviceSimulator={inDeviceSimulator}
        >
            <MenuItem
                selected={currentScreen === SCREEN.HOME}
                bgWhenSelected={settings.accentColor.color}
                onClick={() => handleOnClick(SCREEN.HOME)}
            >
                <Home
                    color={
                        currentScreen === SCREEN.HOME
                            ? settings.accentColor.color
                            : theme.colors.gray3
                    }
                />
                <MenuText
                    color={settings.accentColor.color}
                    selected={currentScreen === SCREEN.HOME}
                    className="apply-font-main"
                >
                    Home
                </MenuText>
            </MenuItem>
            <MenuItem
                selected={currentScreen === SCREEN.DISCOVER}
                bgWhenSelected={settings.accentColor.color}
                onClick={() => handleOnClick(SCREEN.DISCOVER)}
            >
                <Compass
                    color={
                        currentScreen === SCREEN.DISCOVER
                            ? settings.accentColor.color
                            : theme.colors.gray3
                    }
                />
                <MenuText
                    selected={currentScreen === SCREEN.DISCOVER}
                    color={settings.accentColor.color}
                    className="apply-font-main"
                >
                    Discover
                </MenuText>
            </MenuItem>
            <MenuItem
                selected={currentScreen === SCREEN.PROFILE}
                bgWhenSelected={settings.accentColor.color}
                onClick={() => handleOnClick(SCREEN.PROFILE)}
            >
                <User
                    color={
                        currentScreen === SCREEN.PROFILE
                            ? settings.accentColor.color
                            : theme.colors.gray3
                    }
                />
                <MenuText
                    selected={currentScreen === SCREEN.PROFILE}
                    color={settings.accentColor.color}
                    className="apply-font-main"
                >
                    Profile
                </MenuText>
            </MenuItem>
            <MenuItem
                selected={currentScreen === SCREEN.SETTINGS}
                bgWhenSelected={settings.accentColor.color}
                onClick={() => handleOnClick(SCREEN.SETTINGS)}
            >
                <SliderAlt
                    color={
                        currentScreen === SCREEN.SETTINGS
                            ? settings.accentColor.color
                            : theme.colors.gray3
                    }
                />
                <MenuText
                    selected={currentScreen === SCREEN.SETTINGS}
                    color={settings.accentColor.color}
                    className="apply-font-main"
                >
                    Settings
                </MenuText>
            </MenuItem>
        </MenuBar>
    )
}

const MenuBar = styled.div`
    list-style: none;
    display: flex;
    position: ${(props) => (props.isLivePreview ? "fixed" : "absolute")};
    bottom: 0;
    justify-content: space-around;
    width: calc(100% - 16px);
    border-top: 1px solid #c4c4c4;
    padding: 16px 8px 16px 8px;
    padding-top: 16px;
    background: #ffffff;
    margin: 0;
    border-bottom-left-radius: ${(props) =>
        props.inAppPreview || !props.inDeviceSimulator ? "0" : "50px"};
    border-bottom-right-radius: ${(props) =>
        props.inAppPreview || !props.inDeviceSimulator ? "0" : "50px"};
    height: 54px;
    z-index: 1048;
`

const MenuItem = styled.button.attrs((props) => ({
    style: {
        backgroundColor: props.selected
            ? props.bgWhenSelected + "0d"
            : "transparent" /* 0d == 5% */
    }
}))`
    border: none;
    box-shadow: none;
    background: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 1px;
    flex-basis: 25%;
    padding: 6px 0;
    border-radius: 38px;
    &:hover {
        cursor: pointer;
    }
    &:focus {
        outline: none;
    }
`

const MenuText = styled.span.attrs((props) => ({
    style: {
        color: props.selected ? props.color : props.theme.colors.gray3
    }
}))`
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
    margin-top: 6px;
`

export default NavigationBar
