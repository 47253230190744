import React from "react"
import styled from "styled-components/macro"

const Preview = ({
    baseSpacing = 16,
    className,
    bgColor = "#FFFFFF",
    children
}) => {
    return (
        <StyledPreview
            className={className}
            bgColor={bgColor}
            baseSpacing={baseSpacing}
        >
            {children}
        </StyledPreview>
    )
}

const StyledPreview = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: calc(100% - 86px);
    padding: 0 0 ${(props) => props.baseSpacing}px 0;
    overflow-y: scroll;
    background-color: ${(props) => props.bgColor};
    overflow-x: hidden;
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
`

export default Preview
