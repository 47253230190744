export function fallbackCopyTextToClipboard(text) {
    var textArea = document.createElement("textarea")
    textArea.value = text

    // Avoid scrolling to bottom
    textArea.style.top = "0"
    textArea.style.left = "0"
    textArea.style.position = "fixed"

    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
        var successful = document.execCommand("copy")
        //var msg = successful ? "successful" : "unsuccessful"
        if (successful) {
            document.body.removeChild(textArea)
        }
        return true
    } catch (err) {
        console.error("Fallback: Oops, unable to copy", err)
        document.body.removeChild(textArea)
        return false
    }
}

export function copyTextToClipboard(text) {
    if (!navigator.clipboard) {
        return fallbackCopyTextToClipboard(text)
    }
    return navigator.clipboard.writeText(text).then(
        function () {
            return true
        },
        function (err) {
            console.error("Async: Could not copy text: ", err)
            return false
        }
    )
}
