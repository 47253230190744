import React from "react"
import styled from "styled-components/macro"

const TabWrapper = ({ children, isLivePreview }) => {
    return <Wrapper isLivePreview={isLivePreview}>{children}</Wrapper>
}

const Wrapper = styled.div`
    height: ${(props) => (props.isLivePreview ? "100vh" : "calc(100% - 55px)")};
    width: 100%;
    overflow: hidden;
    -ms-overflow-style: none; /* Hide scrollbar for IE and Edge */
    scrollbar-width: none; /* Hide scrollbar for Firefox */
    &::-webkit-scrollbar {
        display: none;
    }
`

export default TabWrapper
