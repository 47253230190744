import React from "react"
import styled from "styled-components/macro"

const Section = ({
    baseSpacing = 16,
    first = false,
    withHeadingOnly = false,
    children
}) => {
    return (
        <StyledSection
            baseSpacing={baseSpacing}
            first={first}
            withHeadingOnly={withHeadingOnly}
        >
            {children}
        </StyledSection>
    )
}

const StyledSection = styled.div`
    padding: ${(props) => props.baseSpacing}px;
    ${(props) => props.first && `padding-top: ${props.baseSpacing * 1.5}px`};
    ${(props) => props.withHeadingOnly && `padding-bottom: 0`};
    display: flex;
    flex-direction: column;
`

export default Section
