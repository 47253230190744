import React from "react"
import styled from "styled-components/macro"

const SharedButton = styled.button`
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    padding: 14px 24px;
    border: none;
    border-radius: 47px;
    margin: 24px auto;
`

export const ButtonPrimary = ({
    accentColor = { color: "#0B4FD5", contrastColor: "#FFFFFF" },
    block = false,
    className,
    children
}) => {
    return (
        <StyledButtonPrimary
            className={className}
            bgColor={accentColor.color}
            textColor={accentColor.contrastColor}
            block={block}
        >
            {children}
        </StyledButtonPrimary>
    )
}

const StyledButtonPrimary = styled(SharedButton).attrs((props) => ({
    style: {
        backgroundColor: props.bgColor,
        color: props.textColor
    }
}))`
    ${(props) => props.block && `width: 100%;`}
`

export const ButtonSecondary = ({
    bgColor = "#E7EDFB",
    textColor = "#0B4FD5",
    block = false,
    className,
    children
}) => {
    return (
        <StyledButtonSecondary
            className={className}
            bgColor={bgColor}
            textColor={textColor}
            block={block}
        >
            {children}
        </StyledButtonSecondary>
    )
}

const StyledButtonSecondary = styled(SharedButton)`
    background-color: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};
    ${(props) => props.block && `width: 100%;`}
`
