import React, { useState, useEffect } from "react"
import styled from "styled-components/macro"

import { FONT_SERVICE } from "../utils/constants"

import FontPicker from "font-picker-react"
import selectedFonts from "../utils/fontLists.json"

import svgCheck from "../assets/icons/svg/check.svg"

import { useSettingsDispatch, useSettingsState } from "../utils/settingsContext"
import useMountEffect from "../utils/useMountEffect"
import fetchGoogleFontCSS from "../utils/fetchGoogleFontCSS"

import useDebounce from "../utils/useDebounce"

const FontSelector = ({ fontSource, pickerId, onRemoveClick }) => {
    const fontSizeRef = {
        regular: "Regular",
        "100": "Thin",
        "200": "Extra Light",
        "300": "Light",
        "500": "Medium",
        "600": "Semi-bold",
        "700": "Bold",
        "800": "Extra-bold",
        "900": "Black"
    }

    const settingsDispatch = useSettingsDispatch()
    const settingsState = useSettingsState()
    const updateURLQuery = useDebounce(
        () =>
            settingsDispatch({
                type: "UPDATE_URL_QUERY"
            }),
        200
    )

    const defaultFontName = "Inter"
    const defaultFontWeights = ["regular"]

    const [, setDefaultFont] = useState(null)
    const [fontList, setFontList] = useState(null)
    const [fontSelected, setFontSelected] = useState(
        settingsState.fonts[pickerId].family || defaultFontName
    )
    const [fontWeights, setFontWeights] = useState(defaultFontWeights)
    const [fontWeightSelected, setFontWeightSelected] = useState("regular")
    const [isFontWeightListVisible, setIsFontWeightListVisible] = useState(
        false
    )
    const [fontWeightCSS, setFontWeightCSS] = useState("")

    const [fontLocalFamily, setFontLocalFamily] = useState(null)
    const [fontLocalWeight, setFontLocalWeight] = useState("regular")

    const [fontListDetailed, setFontListDetailed] = useState(null)

    const buildDetailedGoogleFontList = (googleFontList) => {
        const availableFonts = []
        const selectedGoogleFonts = selectedFonts.find(
            (services) => services.service === FONT_SERVICE.GOOGLE_FONTS
        )
        selectedGoogleFonts.fonts.forEach((font) => {
            const googleFont = googleFontList.find(
                (googleFont) => googleFont.family === font.name
            )
            if (googleFont) {
                availableFonts.push(googleFont)
            }
        })
        setFontListDetailed(availableFonts)
    }

    const fetchGoogleFontsMeta = () => {
        if (fontSource && fontSource === FONT_SERVICE.GOOGLE_FONTS) {
            const apiKey = "AIzaSyCG4735Q3MB6D8rUi-niofECt9Svc7cZsI"
            fetch(
                `https://www.googleapis.com/webfonts/v1/webfonts?key=${apiKey}`
            )
                .then((response) => {
                    return response.json()
                })
                .then((completeFontList) => {
                    buildDetailedGoogleFontList(completeFontList.items)
                })
                .catch((error) => console.error(error))
        }
    }

    useEffect(() => {
        if (fontSource) {
            const fontList = selectedFonts.find(
                (services) => services.service === fontSource
            )
            if (fontList) {
                setFontList(fontList)
                setDefaultFont(
                    fontList.fonts.find((font) => font.name === defaultFontName)
                )
            } else {
                setFontList(null)
            }
        }
    }, [fontSource])

    useMountEffect(fetchGoogleFontsMeta)

    // MARK: Local font functions

    const handleLocalFontsInputChange = (value) => {
        setFontLocalFamily(value)
    }

    const handleOnRemoveClick = () => {
        setFontSelected(defaultFontName)
        settingsDispatch({
            type: "SET_HEADING_FONT",
            fonts: {
                heading: {
                    family: null,
                    weight: "regular"
                }
            }
        })

        updateURLQuery()

        setTimeout(() => {
            onRemoveClick()
        }, 0)
    }

    const renderLocalWeightDropdown = () => {
        const weights = Object.entries(fontSizeRef).map((weight) => {
            return (
                <li
                    key={`local-variant-${weight[0]}`}
                    className="font-list-item"
                >
                    <button
                        type="button"
                        className={`apply-font-${pickerId} font-button`}
                        onClick={() => {
                            setFontLocalWeight(weight[0])
                            setIsFontWeightListVisible(false)
                        }}
                    >
                        {weight[1]}
                    </button>
                </li>
            )
        })
        return weights
    }

    const renderLocalFontsInput = () => {
        return (
            <FontListContainer>
                <style>
                    {`.apply-font-${pickerId} {
                        font-family: "${
                            fontLocalFamily || "sans-serif"
                        }", "Inter";
                        font-weight: ${
                            fontLocalWeight === "regular"
                                ? "400"
                                : fontLocalWeight
                        };
                    `}
                </style>
                <LocalFontInput
                    placeholder="Enter your local font name..."
                    className={`apply-font-${pickerId}`}
                    defaultValue={fontLocalFamily}
                    onChange={(event) =>
                        handleLocalFontsInputChange(event.target.value)
                    }
                />
                <WeightPicker
                    id={`font-picker-local-variant-${pickerId}`}
                    variants={fontWeights}
                    className={`apply-font-${pickerId} ${
                        isFontWeightListVisible ? "expanded" : "non-expanded"
                    }`}
                >
                    <button
                        type="button"
                        className="dropdown-button"
                        onClick={() =>
                            setIsFontWeightListVisible(!isFontWeightListVisible)
                        }
                    >
                        <p
                            className={`dropdown-font-family apply-font-${pickerId}`}
                        >
                            {fontSizeRef[fontLocalWeight]}
                        </p>
                        <p className="dropdown-icon finished"></p>
                    </button>
                    {isFontWeightListVisible && (
                        <ul className="font-list">
                            {renderLocalWeightDropdown()}
                        </ul>
                    )}
                </WeightPicker>
                {pickerId === "heading" && (
                    <RemoveLink onClick={() => handleOnRemoveClick()}>
                        Remove heading font
                    </RemoveLink>
                )}
            </FontListContainer>
        )
    }

    // MARK: General font functions

    /* If adding a header font, set it to whatever the main font is set to */
    const getSelectedFontName = () => {
        if (!settingsState.fonts.heading.family && pickerId === "heading") {
            return settingsState.fonts.main.family
        }
        return fontSelected
    }

    const handleFontWeightSelection = async (weight) => {
        setIsFontWeightListVisible(false)
        setFontWeightSelected(weight)

        // Add CSS for the font weights (as this doesn't seem to entirely work with font-picker-react)

        const css = await fetchGoogleFontCSS(fontSelected, [weight])
        setFontWeightCSS(css)

        let actionType
        switch (pickerId) {
            case "heading":
                actionType = "SET_HEADING_FONT"
                break
            default:
                actionType = "SET_MAIN_FONT"
        }

        settingsDispatch({
            type: actionType,
            fonts: {
                [pickerId]: {
                    family: fontSelected,
                    weight: weight
                }
            }
        })

        updateURLQuery()
    }

    const filterFontWeights = (weights) => {
        return weights.filter((weight) => !weight.includes("italic"))
    }

    const handleFontSelect = (nextFont) => {
        const selectedFontDetails = fontListDetailed.find(
            (font) => font.family === nextFont.family
        )

        // console.log("selectedFontDetails", selectedFontDetails)
        // console.log("nextFont.variants", nextFont.variants)

        const filteredFontWeights = filterFontWeights(
            selectedFontDetails.variants
        )
        setFontWeights(filteredFontWeights || [])
        setFontSelected(nextFont.family)

        let actionType
        switch (pickerId) {
            case "heading":
                actionType = "SET_HEADING_FONT"
                break
            default:
                actionType = "SET_MAIN_FONT"
        }

        settingsDispatch({
            type: actionType,
            fonts: {
                [pickerId]: {
                    family: nextFont.family,
                    weight: "regular"
                }
            }
        })

        updateURLQuery()
    }

    const handleFontPickerClick = () => {
        setIsFontWeightListVisible(false)
    }

    if (!fontList && fontSource !== FONT_SERVICE.LOCAL) {
        return <p>No fonts for this service found</p>
    }

    if (!fontList && fontSource === FONT_SERVICE.LOCAL) {
        return renderLocalFontsInput()
    }

    if (!fontList) {
        return <p>Loading...</p>
    }

    return (
        <FontListContainer>
            <style>{fontWeightCSS}</style>
            <style>
                {`.apply-font-${pickerId}:not(.family-only) {
                        font-weight: ${
                            fontWeightSelected === "regular"
                                ? "400"
                                : fontWeightSelected || "400"
                        }; /* Converting "regular" from Google to "400" which browser understands */
                    `}
            </style>
            <div onClick={() => handleFontPickerClick()}>
                <FontPicker
                    apiKey="AIzaSyCG4735Q3MB6D8rUi-niofECt9Svc7cZsI"
                    pickerId={pickerId}
                    activeFontFamily={getSelectedFontName()}
                    families={fontList.fonts.map((font) => font.name)}
                    onChange={(nextFont) => {
                        handleFontSelect(nextFont)
                    }}
                    limit={100}
                    variants={fontWeights}
                />
            </div>
            {!!fontWeights.length && (
                <WeightPicker
                    id={`font-picker-variant-${pickerId}`}
                    variants={fontWeights}
                    className={`apply-font-${pickerId} family-only ${
                        isFontWeightListVisible ? "expanded" : "non-expanded"
                    }`}
                >
                    <button
                        type="button"
                        className="dropdown-button"
                        onClick={() =>
                            setIsFontWeightListVisible(!isFontWeightListVisible)
                        }
                    >
                        <p
                            className={`dropdown-font-family dont-apply-font-${pickerId} family-only`}
                        >
                            {fontSizeRef[fontWeightSelected]}
                        </p>
                        <p className="dropdown-icon finished"></p>
                    </button>
                    {isFontWeightListVisible && (
                        <ul className="font-list">
                            {fontWeights.map((variant) => {
                                return (
                                    <li
                                        key={`variant${variant}`}
                                        className="font-list-item"
                                    >
                                        <button
                                            type="button"
                                            className={`dont-apply-font-${pickerId} family-only font-button`}
                                            onClick={() => {
                                                handleFontWeightSelection(
                                                    variant
                                                )
                                            }}
                                        >
                                            {fontSizeRef[variant]}
                                        </button>
                                    </li>
                                )
                            })}
                        </ul>
                    )}
                </WeightPicker>
            )}
            {pickerId === "heading" && (
                <RemoveLink onClick={() => handleOnRemoveClick()}>
                    Remove heading font
                </RemoveLink>
            )}
        </FontListContainer>
    )
}

const WeightPicker = styled.div`
    margin-top: 8px;
`

const FontListContainer = styled.div`
    div[id^="font-picker"],
    div[id^="variant-picker"] {
        font-family: "Basier Circle Medium";
        display: flex;
        align-items: flex-start;
        width: 100%;
        border: 1px solid ${(props) => props.theme.colors.gray5};
        border-radius: 8px;
        height: 50px;
        box-shadow: none;
        &.expanded {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            ul {
                display: block;
            }
        }
        .dropdown-button {
            display: flex;
            align-items: center;
            align-self: center;
            justify-content: space-between;
            padding: 0 16px;
            background: none;
        }
        ul {
            display: none;
            position: absolute;
            top: 50px;
            z-index: 1;
            width: calc(100% + 2px);
            margin: 0 0 0 -1px;
            padding: 0;
            overflow-x: hidden;
            overflow-y: auto;
            background: ${(props) => props.theme.colors.white};
            border: 1px solid ${(props) => props.theme.colors.gray5};
            box-shadow: none;
            transition: 0.225s;
            border-radius: 8px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            li button:hover,
            li button.active-font:hover {
                background-color: ${(props) => props.theme.colors.blueLight};
            }
            li button.active-font {
                background-color: ${(props) => props.theme.colors.blueLight};
                background-image: url(${svgCheck});
                background-repeat: no-repeat;
                background-position: calc(100% - 8px) center;
            }
        }
    }

    div[id^="variant-picker"] {
        border: none;
        width: 100%;
    }
`

const RemoveLink = styled.a`
    font-family: "Basier Circle Medium";
    font-weight: normal;
    font-size: 15px;
    color: ${(props) => props.theme.colors.fail};
    cursor: pointer;
    text-align: right;
    display: block;
    padding: 10px 0;
`

const LocalFontInput = styled.input`
    font-family: "Basier Circle";
    display: flex;
    align-items: flex-start;
    width: calc(100% - 32px);
    border: 1px solid ${(props) => props.theme.colors.gray5};
    border-radius: 8px;
    height: 50px;
    box-shadow: none;
    font-size: 16px;
    padding: 0 16px;
`

export default FontSelector
