import io from "socket.io-client"

import { EVENT } from "./enums"
import { SOCKET_ENDPOINT } from "./constants"

let socket

export const initiateSocket = ({ room, create = false, settings = {} }) => {
    socket = io(SOCKET_ENDPOINT)
    console.log("previewSocketFunctions - Initating socket...")
    if (socket && room) {
        if (create) {
            socket.emit(EVENT.CREATE, {
                livePreviewCode: room,
                settings
            })
        } else {
            socket.emit(EVENT.JOIN, {
                livePreviewCode: room
            })
        }
    }
}

export const disconnectSocket = () => {
    console.log("Disconnecting socket...")
    if (socket) {
        socket.disconnect()
    }
}

export const subscribeToSettingsUpdates = (callback) => {
    console.log("Subscribing")
    if (!socket) {
        console.log("No socket, can't subscribe")
        return true
    }
    socket.on(EVENT.SETTINGS_UPDATED, (settings) => {
        console.log("previewSocketFunctions - ON - EVENT.SETTINGS_UPDATED")
        return callback(null, { settings, event: EVENT.SETTINGS_UPDATED })
    })
}

export const loadInitialSettings = (callback) => {
    if (!socket) {
        console.log("No socket, can't load initial settings")
        return true
    }
    socket.on(EVENT.SETTINGS_INITIAL, (settings) => {
        console.log(
            "previewSocketFunctions - ON - EVENT.SETTINGS_INITIAL",
            settings
        )
        return callback(null, { settings, event: EVENT.SETTINGS_INITIAL })
    })
}

export const requestSettings = (room) => {
    if (!socket) {
        console.log("No socket, can't request")
        return true
    }
    socket.emit(EVENT.SETTINGS_REQUEST, {
        livePreviewCode: room
    })
}

export const emitUpdatedSettings = (room, settings) => {
    if (socket) {
        console.log("previewSocketFunctions - EMIT - EVENT.EDITOR_UPDATE")
        socket.emit(EVENT.EDITOR_UPDATE, {
            livePreviewCode: room,
            settings
        })
    }
}
