import React, { useState, useEffect } from "react"
import styled from "styled-components/macro"

import { colorContrast } from "../utils/colorFunctions"
import { Check } from "../assets/icons"
import ContrastIndicator from "./ContrastIndicator"
import ColorSwatch from "../components/ColorSwatch"
import ColorPicker from "./ColorPicker"

const ContrastTool = ({
    color,
    customContrastColor: withCustomContrastColor,
    onCustomContrastColorChange,
    allowChange = true,
    visible = false
}) => {
    const [contrasts, setContrasts] = useState({
        white: {},
        black: {},
        custom: {}
    })
    const [mode, setMode] = useState(allowChange ? "automatic" : "white")
    const [customContrastColor, setCustomContrastColor] = useState(
        withCustomContrastColor
    )

    const setupDefault = () => {
        // Check if we should use white or black text
        const withWhiteText = colorContrast(color, "#FFFFFF")
        const withBlackText = colorContrast(color, "#000000")
        setContrasts({
            ...contrasts,
            white: withWhiteText,
            black: withBlackText
        })
        const textColor =
            withBlackText.ratio > withWhiteText.ratio ? "#FFFFFF" : "#000000"
        onCustomContrastColorChange(textColor)
    }

    const setupWithCustomContrastColor = () => {
        const withCustomText = colorContrast(color, customContrastColor)
        setContrasts({
            ...contrasts,
            custom: withCustomText
        })
        setMode("custom")
        onCustomContrastColorChange(customContrastColor)
    }

    useEffect(() => {
        if (
            customContrastColor &&
            customContrastColor !== "#000000" &&
            customContrastColor !== "#FFFFFF"
        ) {
            setupWithCustomContrastColor()
        } else {
            setupDefault()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [color, customContrastColor])

    const changeMode = (to) => {
        if (allowChange) {
            setMode(to)
            if (to === "automatic") {
                setCustomContrastColor(null)
                onCustomContrastColorChange(null)
            } else if (to === "black") {
                setCustomContrastColor("#000000")
                onCustomContrastColorChange("#000000")
            } else if (to === "white") {
                setCustomContrastColor("#FFFFFF")
                onCustomContrastColorChange("#FFFFFF")
            } else {
                setCustomContrastColor("#222222")
                onCustomContrastColorChange("#222222")
            }
        }
    }

    return (
        <ContrastToolContainer visible={visible}>
            <SubHeading>Contrast</SubHeading>
            <ContrastIndicator
                asHeading
                color={color}
                customContrastColor={customContrastColor}
            />
            <SubHeading>Contrast color</SubHeading>
            <ModeSelector allowSelection={allowChange}>
                <Mode
                    allowSelection={allowChange}
                    hidden={!allowChange}
                    onClick={() => {
                        changeMode("automatic")
                    }}
                >
                    <ColorSwatches style={{ alignSelf: "flex-start" }}>
                        <ColorSwatch
                            color={"#000000"}
                            onShortClick={() => {}}
                        />
                        <ColorSwatch
                            color={"#FFFFFF"}
                            onShortClick={() => {}}
                        />
                    </ColorSwatches>
                    <ModeText>
                        <ModeTextHeading>
                            <span>Automatic</span>
                            {/*
                            {mode === "automatic" &&
                                (contrasts.black > contrasts.black ? (
                                    <ColorSwatch size={18} color={"#000000"} />
                                ) : (
                                    <ColorSwatch size={18} color={"#FFFFFF"} />
                                    ))}
                            */}
                        </ModeTextHeading>
                        <ModeTextParagraph>
                            Uses white as long as contrast is AA Large or
                            better, switch to black if not.
                        </ModeTextParagraph>
                    </ModeText>
                    {mode === "automatic" && (
                        <Check
                            style={{
                                alignSelf: "flex-start",
                                overflow: "visible"
                            }}
                        />
                    )}
                </Mode>
                <Mode
                    allowSelection={allowChange}
                    hidden={!allowChange}
                    onClick={() => {
                        changeMode("black")
                    }}
                >
                    <div>
                        <ColorSwatch
                            color={"#000000"}
                            onShortClick={() => {}}
                        />
                        <ModeText>
                            <ModeTextHeading>Black #000000</ModeTextHeading>
                        </ModeText>
                    </div>
                    <div>
                        {mode === "black" && (
                            <Check
                                style={{
                                    overflow: "visible"
                                }}
                            />
                        )}
                    </div>
                </Mode>
                <Mode
                    onClick={() => {
                        changeMode("white")
                    }}
                    allowSelection={allowChange}
                >
                    <div>
                        <ColorSwatch
                            color={"#FFFFFF"}
                            onShortClick={() => {}}
                        />
                        <ModeText>
                            <ModeTextHeading>White #FFFFFF</ModeTextHeading>
                        </ModeText>
                    </div>
                    <div>
                        {mode === "white" && (
                            <Check
                                style={{
                                    overflow: "visible"
                                }}
                            />
                        )}
                    </div>
                </Mode>
                <Mode
                    onClick={() => changeMode("custom")}
                    hidden={!allowChange}
                    allowSelection={allowChange}
                >
                    <div>
                        <ColorSwatch
                            color={customContrastColor}
                            onShortClick={() => {}}
                        />
                        <ModeText>
                            <ModeTextHeading>Custom</ModeTextHeading>
                        </ModeText>
                    </div>
                    <div>
                        {mode === "custom" && (
                            <Check
                                style={{
                                    overflow: "visible"
                                }}
                            />
                        )}
                    </div>
                </Mode>
                {mode === "custom" && (
                    <ColorPicker
                        hexCode={customContrastColor || "#abcdef"}
                        onChange={(newColor) => {
                            setCustomContrastColor(newColor.hex)
                        }}
                        nested
                    />
                )}
            </ModeSelector>
        </ContrastToolContainer>
    )
}

const ContrastToolContainer = styled.div`
    box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
        rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
    display: ${(props) => (props.visible ? "flex" : "none")};
    flex-direction: column;
    border-radius: 8px;
    padding: 16px;
    overflow: hidden;
    margin-bottom: 16px;
`

const SubHeading = styled.h4`
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: ${(props) => props.theme.colors.black};
    margin-bottom: 0;
`

const ModeSelector = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0 -16px;
    cursor: ${(props) => (props.allowSelection ? "pointer" : "default")};
`

const Mode = styled.div`
    display: ${(props) => (props.hidden ? "none" : "flex")};
    margin: 0 0 0 0;
    align-items: center;
    padding: 16px;
    justify-content: space-between;
    &:hover {
        background-color: ${(props) =>
            props.allowSelection ? props.theme.colors.gray7 : "transparent"};
    }
    > div {
        display: flex;
    }
    > svg {
        display: flex;
        flex: 1 0 auto;
    }
`

const ModeText = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 8px;
`

const ModeTextHeading = styled.h5`
    font-weight: normal;
    font-size: 17px;
    color: ${(props) => props.theme.colors.black};
    margin: 0;
    line-height: 26px;
    display: flex;
    align-items: center;
    span {
        margin-right: 10px;
    }
`

const ModeTextParagraph = styled.p`
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: ${(props) => props.theme.colors.gray2};
    margin: 4px 0;
`

const ColorSwatches = styled.div`
    position: relative;
    min-width: 24px;
    min-height: 24px;
    > div {
        width: 14px;
        height: 14px;
        position: absolute;
    }
    > div:first-child {
        top: 0;
        left: 0;
    }
    > div:last-child {
        bottom: 0;
        right: 0;
    }
`

export default ContrastTool
