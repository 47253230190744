import React, { useState } from "react"
import styled from "styled-components/macro"

import { useSettingsState } from "../utils/settingsContext"

import Spinner from "react-svg-spinner"

const ShareButton = ({ text, onClick, icon }) => {
    const [loading, setLoading] = useState(false)
    const settingsState = useSettingsState()

    const handleClick = () => {
        setLoading(true)
        onClick()
    }

    return (
        <StyledShareButton onClick={handleClick}>
            <Circled>
                {!loading && icon}
                {loading && <Spinner color={settingsState.accentColor.color} />}
            </Circled>
            {text}
        </StyledShareButton>
    )
}

const StyledShareButton = styled.button`
    font-family: "Basier Circle";
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    font-size: 17px;
    margin-top: 16px;
    min-height: 50px;
`

const Circled = styled.span`
    background-color: ${(props) => props.theme.colors.blueLight};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
`

export default ShareButton
