import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components/macro"

import PreviewPhone12 from "../../../assets/images/preview_phone_12_A.svg"
import PreviewPhone12Speaker from "../../../assets/images/preview_phone_12_A_Speaker.svg"

import { Cellular, Wifi, Battery } from "../../../assets/icons"

import AppPreview from "./AppPreview"
import { useSettingsState } from "../../../utils/settingsContext"
import useDimensions from "../../../utils/useDimensions"

const Mobile = () => {
    const settingsState = useSettingsState()

    const componentRef = useRef(null)
    const { width: topbarWidth } = useDimensions(componentRef)

    const [topbarWidthWithinLimits, setTopbarWidthWithinLimits] = useState(null)

    const [timestamp, setTimestamp] = useState(
        new Date().toLocaleTimeString("nb-NO", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false
        })
    )

    useEffect(() => {
        let interval = null
        interval = setInterval(() => {
            const date = new Date()
            setTimestamp(
                date.toLocaleTimeString("nb-NO", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false
                })
            )
        }, 1000)
        return () => clearInterval(interval)
    }, [timestamp])

    const topbarLimits = {
        max: 412,
        min: 312
    }

    useEffect(() => {
        if (topbarWidth <= topbarLimits.min) {
            setTopbarWidthWithinLimits(topbarLimits.min)
        } else if (topbarWidth >= topbarLimits.max) {
            setTopbarWidthWithinLimits(topbarLimits.max)
        } else {
            setTopbarWidthWithinLimits(topbarWidth)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topbarWidth])

    return (
        <Layered>
            <Wrapper>
                <PreviewContentContainer>
                    <AppleTop
                        bgColor={settingsState.appBackgroundColor}
                        ref={componentRef}
                    >
                        <Clock widthMetric={topbarWidthWithinLimits}>
                            {timestamp}
                        </Clock>
                        <StatusbarIcons widthMetric={topbarWidthWithinLimits}>
                            <Cellular />
                            <Wifi />
                            <Battery />
                        </StatusbarIcons>
                        <Speaker src={PreviewPhone12Speaker} alt="" />
                    </AppleTop>
                    <AppPreview inDeviceSimulator />
                </PreviewContentContainer>
                <PreviewFrame
                    widthMetric={topbarWidth}
                    src={PreviewPhone12}
                    alt="Logo"
                />
            </Wrapper>
        </Layered>
    )
}

const Layered = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`

const Wrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`

const PreviewFrame = styled.img`
    z-index: 1024;
    max-height: 95vh;
    height: 940px;
    max-width: 464px;
    width: auto;
`

const Speaker = styled.img`
    position: absolute;
    top: -1.5px;
    left: 16.5%;
    width: 65%;
`

const PreviewContentContainer = styled.div`
    position: absolute;
    width: calc(100% - 11%);
    height: calc(100% - 5%);
    border-radius: 40px;
    z-index: 1025;
`

const AppleTop = styled.div`
    height: 55px;
    width: 100%;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    background-color: ${(props) => props.bgColor};
`

const scaleDefaults = {
    max: {
        width: 412,
        value: 1.0
    },
    min: {
        width: 312,
        value: 0.8
    }
}

const topPositionDefaults = {
    max: {
        width: 412,
        value: 12
    },
    min: {
        width: 312,
        value: 8
    }
}

function getDerivedValue(widthMetric, defaults) {
    if (widthMetric >= defaults.max.width) {
        return defaults.max.value
    }
    if (widthMetric <= defaults.min.width) {
        return defaults.min.value
    }
    const pixelRange = defaults.max.width - defaults.min.width
    const positionRange = defaults.max.value - defaults.min.value
    const percentageOfRangeInverted =
        100 - ((widthMetric - defaults.min.width) * 100) / pixelRange
    const result =
        defaults.max.value - (percentageOfRangeInverted / 100) * positionRange
    return result
}

const Clock = styled.p`
    position: absolute;
    top: ${(props) =>
        getDerivedValue(props.widthMetric, topPositionDefaults)}px;
    left: 0;
    font-size: 16px;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    font-weight: 600;
    margin: 0;
    width: 18.5%;
    text-align: right;
    transform: scale(
        ${(props) => getDerivedValue(props.widthMetric, scaleDefaults)}
    );
    transform-origin: center right;
`

const StatusbarIcons = styled.div`
    position: absolute;
    top: ${(props) =>
        getDerivedValue(props.widthMetric, topPositionDefaults)}px;
    right: 12px;
    width: 18.5%;
    white-space: nowrap;
    transform: scale(
        ${(props) => getDerivedValue(props.widthMetric, scaleDefaults)}
    );
    transform-origin: center left;
    > svg {
        margin-right: 4px;
        &:last-of-type {
            margin-right: 0;
        }
    }
`

export default Mobile
