import * as React from "react"

function SvgPlus(props) {
    return (
        <svg width={24} height={24} fill="none" {...props}>
            <path d="M19 11h-6V5h-2v6H5v2h6v6h2v-6h6v-2z" fill="#0D1930" />
        </svg>
    )
}

export default SvgPlus
