import * as React from "react"

function SvgSwitchOff({ color }) {
    return (
        <svg width={50} height={28} fill="none">
            <rect
                x={1.5}
                y={1.5}
                width={47}
                height={25}
                rx={12.5}
                fill="#D3D6DD"
                stroke="#D3D6DD"
                strokeWidth={3}
            />
            <circle cx={14} cy={14} r={12} fill="#FFFFFA" />
        </svg>
    )
}

export default SvgSwitchOff
