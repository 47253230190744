import * as React from "react"

function SvgKey({ color }) {
    return (
        <svg width={19} height={10} fill="none">
            <path
                d="M5 10a5.007 5.007 0 004.898-4H12v2h2V6h2v3h2V6h1V4H9.898A5.007 5.007 0 005 0C2.243 0 0 2.243 0 5s2.243 5 5 5zm0-8c1.654 0 3 1.346 3 3S6.654 8 5 8 2 6.654 2 5s1.346-3 3-3z"
                fill={color}
            />
        </svg>
    )
}

export default SvgKey
