import React, { useState, useEffect } from "react"
import { CustomPicker } from "react-color"
import styled from "styled-components/macro"
import ColorSwatch from "../components/ColorSwatch"

import { Plus } from "../assets/icons"

import { HexColorPicker, HexColorInput } from "react-colorful"
import "react-colorful/dist/index.css"

const tinycolor = require("tinycolor2")

const ColorPicker = ({ hexCode, onChange, nested }) => {
    // colors={["#5A3ADF", "#930036", "#3ADF90", "#FFCE22"]}
    const [swatches, setSwatches] = useState([])
    const saveSwatch = () => {
        if (!swatches.find((swatch) => swatch === colorHex)) {
            const allSwatches = [...swatches, colorHex]
            setSwatches(allSwatches)
            localStorage.setItem(
                "agens.styletile.swatches",
                JSON.stringify(allSwatches)
            )
        }
    }

    const handleUpdatedSwatches = (newSwatches) => {
        setSwatches(newSwatches)
    }

    const displayColorSwatches = (colors) => {
        return colors.map((color) => {
            return (
                <ColorSwatch
                    inList
                    key={"colorswatch" + color}
                    color={color}
                    onShortClick={() => onChange(color)}
                    onUpdatesSwatches={handleUpdatedSwatches}
                />
            )
        })
    }

    const [colorHex, setColorHex] = useState("#ffffff")
    const [colorHsl, setColorHsl] = useState({ h: 0, s: 0, l: 0 })

    const handleColorInputChangeHex = (hex) => {
        const parsedColor = tinycolor(hex)
        if (parsedColor.isValid()) {
            const hsl = parsedColor.toHsl()
            setColorHsl(hsl)
            setColorHex(hex)
            onChange(hex)
        }
    }

    const handleColorInputChangeHsl = (hsl) => {
        const parsedColor = tinycolor(hsl)
        if (parsedColor.isValid()) {
            const hex = parsedColor.toHexString()
            setColorHsl(hsl)
            setColorHex(hex)
            onChange(hex)
        }
    }

    useEffect(() => {
        const swatches = localStorage.getItem("agens.styletile.swatches")
        if (swatches) {
            setSwatches(JSON.parse(swatches))
        }
        const parsedColor = tinycolor(hexCode)
        if (parsedColor.isValid()) {
            setColorHsl(parsedColor.toHsl())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const parsedColor = tinycolor(hexCode)
        if (parsedColor.isValid()) {
            setColorHex(hexCode)
            onChange(hexCode)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hexCode])

    return (
        <ColorPickerContainer nested={nested}>
            <style>
                {`
                    /* RESET */
                    input[type=range] {
                        -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
                        background: #fff; /* Otherwise white in Chrome */
                        border-color: transparent;

                    }

                    input[type=range]::-webkit-slider-thumb {
                        -webkit-appearance: none;
                    }

                    input[type=range]:focus {
                        outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
                    }

                    input[type=range]::-ms-track {
                        cursor: pointer;
                        /* Hides the slider so custom styles can be added */
                        background: #E9EBEF; 
                        border-color: transparent;
                        color: #E9EBEF;
                    }

                    /* STYLE */
                    /* Special styling for WebKit/Blink */
                    input[type=range]::-webkit-slider-thumb {
                        -webkit-appearance: none;
                        border: 1px solid transparent;
                        height: 16px;
                        width: 16px;
                        border-radius: 8px;
                        background: #ffffff;
                        cursor: pointer;
                        margin-top: -7px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
                        margin-bottom: -7px;
                        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
                    }

                    /* All the same stuff for Firefox */
                    input[type=range]::-moz-range-thumb {
                        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
                        border: 1px solid transparent;
                        height: 16px;
                        width: 16px;
                        border-radius: 8px;
                        background: #ffffff;
                        cursor: pointer;
                    }

                    /* All the same stuff for IE */
                    input[type=range]::-ms-thumb {
                        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
                        border: 1px solid transparent;
                        height: 16px;
                        width: 16px;
                        border-radius: 8px;
                        background: #ffffff;
                        cursor: pointer;
                    }

                    input[type=range]::-webkit-slider-runnable-track {
                        width: 100%;
                        height: 4px;
                        cursor: pointer;
                        background: #E9EBEF;
                        border-radius: 1.3px;
                    }

                    input[type=range]:focus::-webkit-slider-runnable-track {
                        background: #E9EBEF;
                    }

                    input[type=range]::-moz-range-track {
                        width: 100%;
                        height: 4px;
                        cursor: pointer;
                        background: #E9EBEF;
                        border-radius: 1.3px;
                    }

                    input[type=range]::-ms-track {
                        width: 100%;
                        height: 4px;
                        cursor: pointer;
                        background: transparent;
                        border-color: transparent;
                        border-width: 16px 0;
                        color: transparent;
                    }
                    input[type=range]::-ms-fill-lower {
                        background: #E9EBEF;
                        border-radius: 2.6px;
                    }
                    input[type=range]:focus::-ms-fill-lower {
                        background: #E9EBEF;
                    }
                    input[type=range]::-ms-fill-upper {
                        background: #E9EBEF;
                        border-radius: 2.6px;
                    }
                    input[type=range]:focus::-ms-fill-upper {
                        background: #E9EBEF;
                    }
                `}
            </style>
            <HexColorPicker
                color={colorHex}
                onChange={handleColorInputChangeHex}
            />
            <ColorInputs>
                <ColorInputWrapperHex>
                    <label>HEX</label>
                    <HexColorInput
                        name="hex"
                        color={colorHex.toUpperCase()}
                        onChange={handleColorInputChangeHex}
                    />
                </ColorInputWrapperHex>
                <ColorInputWrapperHsl>
                    <InputHslWrapper>
                        <InputHslMeta>
                            <label>Hue</label>
                            <span>{Math.round(colorHsl.h)}</span>
                        </InputHslMeta>
                        <InputH
                            type="range"
                            min="0"
                            max="359"
                            name="hsl.h"
                            value={Math.round(colorHsl.h)}
                            onChange={(event) =>
                                handleColorInputChangeHsl({
                                    ...colorHsl,
                                    h: Number(event.target.value)
                                })
                            }
                        />
                    </InputHslWrapper>
                    <InputHslWrapper>
                        <InputHslMeta>
                            <label>Saturation</label>
                            <span>{Math.round(colorHsl.s * 100)}</span>
                        </InputHslMeta>
                        <InputS
                            name="hsl.s"
                            type="range"
                            min="0"
                            max="1"
                            step="0.01"
                            value={colorHsl.s}
                            onChange={(event) =>
                                handleColorInputChangeHsl({
                                    ...colorHsl,
                                    s: Number(event.target.value)
                                })
                            }
                        />
                    </InputHslWrapper>
                    <InputHslWrapper>
                        <InputHslMeta>
                            <label>Lightness</label>
                            <span>{Math.round(colorHsl.l * 100)}</span>
                        </InputHslMeta>
                        <InputL
                            name="hsl.l"
                            type="range"
                            min="0"
                            max="1"
                            step="0.01"
                            value={colorHsl.l}
                            onChange={(event) =>
                                handleColorInputChangeHsl({
                                    ...colorHsl,
                                    l: Number(event.target.value)
                                })
                            }
                        />
                    </InputHslWrapper>
                </ColorInputWrapperHsl>
            </ColorInputs>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column"
                }}
            >
                <InputLabel>Saved colors</InputLabel>
                <div
                    style={{
                        display: "flex",
                        width: "100%",
                        flexWrap: "wrap"
                    }}
                >
                    {!!swatches.length && displayColorSwatches(swatches)}
                    <AddButton onClick={() => saveSwatch()}>
                        <Circled>
                            <Plus />
                        </Circled>
                    </AddButton>
                </div>
            </div>
        </ColorPickerContainer>
    )
}

const ColorPickerContainer = styled.div`
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    padding: 16px;
    overflow: hidden;
    box-shadow: ${(props) =>
        !props.nested
            ? "rgba(0, 0, 0, 0.2) 0px 3px 1px -2px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px"
            : "none"};

    .react-colorful {
        width: 100%;
        height: 250px;
    }
    .react-colorful__saturation {
        border-radius: 8px;
    }
    .react-colorful__last-control {
        margin: 16px 0;
        border-radius: 8px;
        height: 16px;
    }
`

const ColorInputs = styled.div`
    display: flex;
    > &:first-child {
        flex-basis: 42%;
    }
`

const ColorInputWrapperHex = styled.div`
    display: flex;
    flex-direction: column;
    > label {
        font-family: "Basier Circle Medium";
        font-weight: normal;
        font-size: 15px;
        margin-bottom: 8px;
        text-transform: uppercase;
    }
    > input {
        border: 1px solid ${(props) => props.theme.colors.gray5};
        border-radius: 8px;
        font-size: 17px;
        padding: 16px;
        width: calc(100% - 32px - 16px);
    }
`

const ColorInputWrapperHsl = styled.div`
    display: flex;
    margin-right: 7px;
    flex-direction: column;
    flex: 1 0 55%;
`

const InputHslWrapper = styled.div`
    display: flex;
    flex-direction: column;
`

const InputHslMeta = styled.div`
    display: flex;
    justify-content: space-between;
    > label {
        font-family: "Basier Circle Medium";
        font-size: 15px;
    }
`

const InputHsl = styled.input`
    border: 1px solid ${(props) => props.theme.colors.gray5};
    border-radius: 8px;
    font-size: 17px;
    margin: 8px 0 16px;
    min-width: 0;
    text-align: center;
    width: calc(100%);
`

const InputH = styled(InputHsl)`
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
`
const InputS = styled(InputHsl)`
    border-radius: 0;
    border-left: none;
    border-right: none;
`
const InputL = styled(InputHsl)`
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
`

const InputLabel = styled.label`
    font-family: "Basier Circle Medium";
    font-weight: normal;
    font-size: 15px;
    margin-bottom: 8px;
`

const AddButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    cursor: pointer;
`

const Circled = styled.span`
    background-color: ${(props) => props.theme.colors.white};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid ${(props) => props.theme.colors.gray4};
`

export default CustomPicker(ColorPicker)
