import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components/macro"
import Header from "../../../components/Preview/Header"
import Preview from "../../../components/Preview/Preview"
import { H1, H4 } from "../../../components/Preview/Headings"
import Bleed from "../../../components/Preview/Bleed"
import Section from "../../../components/Preview/Section"

import { useSettingsState } from "../../../utils/settingsContext"

import {
    Envelope,
    Key,
    CreditCard,
    Bell,
    Location,
    SwitchOff,
    SwitchOn,
    ChevronUp
} from "../../../assets/icons"
import { BodyLarge } from "../../../components/Preview/BodyText"
import { ButtonPrimary } from "../../../components/Preview/Buttons"
import TabWrapper from "../../../components/Preview/TabWrapper"

const Settings = ({ liveSettings = null }) => {
    const theme = useContext(ThemeContext)

    const baseSpacing = 16

    let settings
    const settingsState = useSettingsState()

    if (liveSettings) {
        // console.log("Have props liveSettings", liveSettings)
        settings = liveSettings
    } else {
        // console.log("No settings by props, using state/context")
        settings = settingsState
    }

    return (
        <TabWrapper isLivePreview={!!liveSettings}>
            <Preview
                className="preview-mobile"
                baseSpacing={baseSpacing}
                bgColor={theme.colors.white}
            >
                <Bleed sticky baseSpacing={baseSpacing}>
                    <Header baseSpacing={baseSpacing} shadow={false}>
                        <H1
                            className="apply-font-main apply-font-heading"
                            textColor={settings.textColor.color}
                        >
                            Settings
                        </H1>
                    </Header>
                </Bleed>
                <Bleed baseSpacing={baseSpacing}>
                    <Section baseSpacing={baseSpacing} first withHeadingOnly>
                        <H4
                            className="apply-font-main apply-font-heading"
                            textColor={settings.textColor.color}
                        >
                            Account settings
                        </H4>
                    </Section>
                    <SettingsList>
                        <Setting hoverBgColor={settings.accentColor.color}>
                            <SettingIcon>
                                <Envelope color={settings.accentColor.color} />
                            </SettingIcon>
                            <SettingText>
                                <BodyLarge
                                    className="apply-font-main"
                                    textColor={settings.textColor.color}
                                    bottomMargin={false}
                                >
                                    E-mail address
                                </BodyLarge>
                            </SettingText>
                            <SettingAction rotateContent={90}>
                                <ChevronUp />
                            </SettingAction>
                        </Setting>
                        <Setting hoverBgColor={settings.accentColor.color}>
                            <SettingIcon>
                                <Key color={settings.accentColor.color} />
                            </SettingIcon>
                            <SettingText>
                                <BodyLarge
                                    className="apply-font-main"
                                    textColor={settings.textColor.color}
                                    bottomMargin={false}
                                >
                                    Password
                                </BodyLarge>
                            </SettingText>
                            <SettingAction rotateContent={90}>
                                <ChevronUp />
                            </SettingAction>
                        </Setting>
                        <Setting hoverBgColor={settings.accentColor.color}>
                            <SettingIcon>
                                <CreditCard
                                    color={settings.accentColor.color}
                                />
                            </SettingIcon>
                            <SettingText>
                                <BodyLarge
                                    className="apply-font-main"
                                    textColor={settings.textColor.color}
                                    bottomMargin={false}
                                >
                                    Payment
                                </BodyLarge>
                            </SettingText>
                            <SettingAction rotateContent={90}>
                                <ChevronUp />
                            </SettingAction>
                        </Setting>
                    </SettingsList>
                    <Section withHeadingOnly>
                        <H4
                            className="apply-font-main apply-font-heading"
                            textColor={settings.textColor.color}
                        >
                            Device settings
                        </H4>
                    </Section>
                    <SettingsList>
                        <Setting hoverBgColor={settings.accentColor.color}>
                            <SettingIcon>
                                <Bell color={settings.accentColor.color} />
                            </SettingIcon>
                            <SettingText>
                                <BodyLarge
                                    className="apply-font-main"
                                    textColor={settings.textColor.color}
                                    bottomMargin={false}
                                >
                                    Notifications
                                </BodyLarge>
                            </SettingText>
                            <SettingAction>
                                <SwitchOn color={settings.accentColor.color} />
                            </SettingAction>
                        </Setting>
                        <Setting hoverBgColor={settings.accentColor.color}>
                            <SettingIcon>
                                <Location color={settings.accentColor.color} />
                            </SettingIcon>
                            <SettingText>
                                <BodyLarge
                                    className="apply-font-main"
                                    textColor={settings.textColor.color}
                                    bottomMargin={false}
                                >
                                    Location
                                </BodyLarge>
                            </SettingText>
                            <SettingAction>
                                <SwitchOff color={settings.accentColor.color} />
                            </SettingAction>
                        </Setting>
                    </SettingsList>
                    <Section>
                        <ButtonPrimary
                            accentColor={settings.accentColor}
                            className="apply-font-main"
                            block
                        >
                            Sign out
                        </ButtonPrimary>
                    </Section>
                </Bleed>
            </Preview>
        </TabWrapper>
    )
}

const SettingsList = styled.ul`
    list-style: none;
    padding: 0;
    margin-top: 8px;
`

const Setting = styled.li`
    align-items: center;
    display: flex;
    display: flex;
    padding: 9px 16px 9px 8px;
    &:hover {
        background-color: ${(props) => props.hoverBgColor}0d; /* 0d == 5% */
    }
`

const SettingIcon = styled.div`
    width: 36px;
    text-align: center;
    margin-right: 6px;
    padding-top: 4px;
`

const SettingText = styled.div`
    display: flex;
    flex: 1;
`

const SettingAction = styled.div`
    ${(props) =>
        props.rotateContent && `transform: rotate(${props.rotateContent}deg)`};
    height: 28px;
`

export default Settings
