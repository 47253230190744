import React, { useEffect, useState } from "react"
import styled from "styled-components/macro"
import BoxHeader from "../components/BoxHeader"
import CopyShareURLButton from "../components/CopyShareURLButton"
import ShareButton from "../components/ShareButton"

import { useSettingsState } from "../utils/settingsContext"

import makeId from "../utils/makeId"

import {
    initiateSocket,
    disconnectSocket,
    emitUpdatedSettings
} from "../utils/previewSocketFunctions"

import url from "../utils/currentUrl"
import { PRODUCTION, SOCKET_ENDPOINT } from "../utils/constants"
import { MobileAlt } from "../assets/icons"

// import socketIOClient from "socket.io-client"

const LivePreviewSection = () => {
    const settingsState = useSettingsState()

    const [generatedLivePreviewLink, setGeneratedLivePreviewLink] = useState(
        null
    )
    const [
        generatedLivePreviewQRCode,
        setGeneratedLivePreviewQRCode
    ] = useState(null)

    const [livePreviewCode, setLivePreviewCode] = useState(null)

    const generateQRCode = async (url) => {
        fetch("/.netlify/functions/qr", {
            method: "POST",
            body: JSON.stringify({
                url
            })
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.text()
                }
                return Promise.reject(response.status)
            })
            .then((svg) => {
                setGeneratedLivePreviewQRCode(svg)
            })
            .catch((error) => {
                // TODO show error message
                console.error("generateQRCode error", error)
            })
    }

    const setupLivePreviewLink = () => {
        const code = PRODUCTION ? makeId(6) : "AAAAAA"
        setGeneratedLivePreviewLink(`${url.protocol}//${url.host}/p/${code}`)
        generateQRCode(`${url.protocol}//${url.host}/p/${code}`)
        setLivePreviewCode(code)
    }

    useEffect(() => {
        if (livePreviewCode) {
            initiateSocket({
                endpoint: SOCKET_ENDPOINT,
                room: livePreviewCode,
                settings: settingsState,
                create: true
            })
        }
        return () => {
            disconnectSocket()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [livePreviewCode])

    useEffect(() => {
        emitUpdatedSettings(livePreviewCode, settingsState)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsState])

    return (
        <>
            <BoxHeader>Live Preview</BoxHeader>
            {!generatedLivePreviewLink && (
                <ShareButton
                    text="Start live preview"
                    onClick={setupLivePreviewLink}
                    icon={<MobileAlt />}
                />
            )}
            {generatedLivePreviewLink && (
                <>
                    <CopyShareURLButton url={generatedLivePreviewLink} />
                    {generatedLivePreviewQRCode && (
                        <QRAndCodeWrapper>
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: generatedLivePreviewQRCode
                                }}
                            />
                            <CodeWrapper>
                                <CodeLabel>Connection code</CodeLabel>
                                <Code>{livePreviewCode}</Code>
                            </CodeWrapper>
                        </QRAndCodeWrapper>
                    )}
                </>
            )}
        </>
    )
}

const QRAndCodeWrapper = styled.div`
    display: flex;
    span {
        width: 50%;
    }
`

const CodeWrapper = styled.div`
    display: flex;
    flex: 0 0 50%;
    margin: 17px 0 16px 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: ${(props) => props.theme.colors.gray7};
`

const CodeLabel = styled.p`
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: center;
    margin: 0 0 8px 0;
`
const Code = styled.p`
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    text-align: center;
    margin: 0;
`

export default LivePreviewSection
