import React, { useState } from "react"
import styled from "styled-components/macro"
import { Check, Copy } from "../assets/icons"

import { useSettingsState } from "../utils/settingsContext"
import { copyTextToClipboard } from "../utils/clipboard"

import Spinner from "react-svg-spinner"

const CopyShareURLButton = ({ url }) => {
    const [loading, setLoading] = useState(false)
    const [completed, setCompleted] = useState(false)

    const settingsState = useSettingsState()

    const copyShareableLink = () => {
        if (copyTextToClipboard(url)) {
            setCompleted(true)
            setLoading(false)
            setTimeout(() => {
                setCompleted(false)
            }, 3000)
        }
    }

    const handleClick = () => {
        setLoading(true)
        copyShareableLink()
    }

    return (
        <StyledShareButton onClick={handleClick}>
            <TextWrapper>
                <URL>
                    {!completed ? (
                        url
                    ) : (
                        <CopiedText>Copied to clipboard</CopiedText>
                    )}
                </URL>
            </TextWrapper>
            {!loading && !completed && <Copy />}
            {loading && !completed && (
                <Spinner color={settingsState.accentColor.color} />
            )}
            {completed && <Check />}
        </StyledShareButton>
    )
}

const StyledShareButton = styled.button`
    font-family: "Basier Circle";
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    background: none;
    font-size: 17px;
    margin-top: 16px;
    text-align: left;
    width: 100%;
    border: 1px solid ${(props) => props.theme.colors.gray5};
    border-radius: 8px;
    padding: 12px 16px;
`

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-width: calc(100% - 32px);
`

const URL = styled.span``

const CopiedText = styled.span`
    font-size: 17px;
`

export default CopyShareURLButton
