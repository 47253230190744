import { useState, useEffect, useReducer, useRef } from "react"

const reducer = (_, width) => {
    const newState = {}
    newState.mobile = width <= 375
    newState.tablet = width <= 768
    newState.desktop = width <= 1200
    newState.width = width
    return newState
}

const initialState = {
    mobile: false,
    tablet: false,
    desktop: false,
    width: null
}

export default () => {
    const [node, ref] = useState(null)
    const [matches, dispatch] = useReducer(reducer, initialState)
    const observerRef = useRef(null)

    if (!observerRef.current) {
        observerRef.current = new ResizeObserver(([entry]) => {
            dispatch(entry.borderBoxSize?.inlineSize ?? entry.contentRect.width)
        })
    }

    useEffect(() => {
        const { current: observer } = observerRef
        if (node) {
            dispatch(node.getBoundingClientRect().width)
            observer.observe(node)
        }
        return () => {
            observer.disconnect()
        }
    }, [node])

    return [matches, ref]
}
