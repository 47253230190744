import React from "react"

import Box from "../components/Box"
import Paragraph from "../components/Paragraph"
import BoxHeader from "../components/BoxHeader"
import Switcher from "../components/Switcher"

// import { Desktop, Tab, MobileAlt } from "../assets/icons"

import { MEDIUM /*, WEBSITE_SIZE */ } from "../utils/constants"
import ColorSection from "./ColorSection"
import FontSection from "./FontSection"

import ShareSection from "./ShareSection"
import LivePreviewSection from "./LivePreviewSection"
import Brand from "../components/Brand"
import Warning from "../components/Warning"
import { H1, H2 } from "../components/Heading"

const Sidebar = ({
    previewMedium,
    previewWebsiteWidth,
    onPreviewSetupChange
}) => {
    return (
        <main>
            <Brand />
            <Warning className="smallScreenWarning">
                <H2>Small screen</H2>
                <Paragraph>
                    You can't (yet!) edit and preview simultaneously on small
                    screens. We're working on it, though!
                </Paragraph>
                <Paragraph>
                    You can use another device with Live Preview in the
                    meantime.
                </Paragraph>
            </Warning>
            <Box>
                <BoxHeader>Medium</BoxHeader>
                <Switcher
                    options={[
                        {
                            title: MEDIUM.MOBILE_APP
                        },
                        {
                            title: MEDIUM.WEBSITE
                        }
                    ]}
                    defaultOption={previewMedium}
                    onChange={(medium) =>
                        onPreviewSetupChange({
                            previewMedium: medium,
                            previewWebsiteWidth
                        })
                    }
                />
                {/*
                {previewMedium === MEDIUM.WEBSITE && (
                    <Switcher
                        options={[
                            { icon: <Desktop />, title: WEBSITE_SIZE.DESKTOP },
                            { icon: <Tab />, title: WEBSITE_SIZE.TABLET },
                            { icon: <MobileAlt />, title: WEBSITE_SIZE.MOBILE }
                        ]}
                        defaultOption={previewWebsiteWidth}
                        onChange={(websiteWidth) =>
                            onPreviewSetupChange({
                                previewWebsiteWidth: websiteWidth,
                                previewMedium
                            })
                        }
                    />
                )}
                */}
            </Box>
            <Box>
                <FontSection />
            </Box>
            <Box>
                <ColorSection />
            </Box>
            <Box>
                <ShareSection />
            </Box>
            <Box>
                <LivePreviewSection />
            </Box>
            <Box>
                <BoxHeader>About this site</BoxHeader>
                <Paragraph>
                    StyleTile is a tool made for designers to help make the
                    creation of digital brands a more interactive and inclusive
                    process.
                </Paragraph>
                <Paragraph>
                    The idea for this tool came from our own experiences working
                    with branding. We often found that the branding process was
                    separated from the actual product being made. Turns out, a
                    nice looking styleguide and a functional app are two very
                    different things.
                </Paragraph>
                <Paragraph>
                    By instead seeing fonts and colors on a realistic prototype,
                    you get a much better picture of how your brand will look
                    when implemented. And with contrast checking built in, you
                    ensure the brand you’re developing meets usability
                    standards.
                </Paragraph>
                <Paragraph>
                    And so, we hope you find StyleTile useful. If you do, or
                    have suggestions on how it could be better, send an email to{" "}
                    <a href="mailto:hello@styletile.io">hello@styletile.io</a>.
                    Until then, see ya!
                </Paragraph>
                <Paragraph>
                    Espen Benoni
                    <br />
                    Founder & designer at StyleTile.
                </Paragraph>
                <Paragraph>
                    Handcrafted at <a href="https://agens.no">Agens</a>.
                </Paragraph>
            </Box>
        </main>
    )
}

export default Sidebar
