export const EVENT = {
    CREATE: "CREATE",
    JOIN: "JOIN",
    CLIENT_JOINED: "CLIENT_JOINED",
    EDITOR_UPDATE: "EDITOR_UPDATE",
    SETTINGS_INITIAL: "SETTINGS_INITIAL",
    SETTINGS_UPDATED: "SETTINGS_UPDATED",
    SETTINGS_REQUEST: "SETTINGS_REQUEST"
}

export const SCREEN = {
    HOME: "HOME",
    DISCOVER: "DISCOVER",
    PROFILE: "PROFILE",
    SETTINGS: "SETTINGS"
}

export const APP_BACKGROUND_COLOR_FOR_SCREEN = {
    [SCREEN.HOME]: "#FFFFFF",
    [SCREEN.DISCOVER]: "#FFFFFF",
    [SCREEN.PROFILE]: "#F5F6F9",
    [SCREEN.SETTINGS]: "#FFFFFF"
}
