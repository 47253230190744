import React from "react"
import styled from "styled-components/macro"

const Paragraph = ({ children }) => {
    return <StyledParagraph>{children}</StyledParagraph>
}

const StyledParagraph = styled.p`
    font-size: 16px;
    line-height: 20px;
`

export default Paragraph
