import React, { useState, useEffect, useContext } from "react"
import styled, { ThemeContext } from "styled-components/macro"

import { useSettingsState } from "../../utils/settingsContext"
import useWidthObserver from "../../utils/useWidthObserver"

import WebsiteBg01 from "./WebsiteBg01.jpg"
import WebsiteBg02 from "./WebsiteBg02.jpg"
import News01 from "./News01.jpg"
import News02 from "./News02.jpg"
import News03 from "./News03.jpg"
import { Rocket, Meteor, Atom, Check } from "../../assets/icons"

import { WEBSITE_SIZE } from "../../utils/constants"

const Website = ({ width }) => {
    const theme = useContext(ThemeContext)

    const settings = useSettingsState()
    const [wrapperWidth, setWrapperWidth] = useState(null)
    const [contentWidth, setContentWidth] = useState(null)

    const [sizeIs, ref] = useWidthObserver()

    useEffect(() => {
        switch (width) {
            case WEBSITE_SIZE.MOBILE: {
                setWrapperWidth(375)
                setContentWidth(335)
                break
            }
            case WEBSITE_SIZE.TABLET: {
                setWrapperWidth(768)
                setContentWidth(688)
                break
            }
            default: {
                // WEBSITE_SIZE.DESKTOP
                setWrapperWidth(2560)
                setContentWidth(1180)
            }
        }
    }, [width])

    return (
        <Wrapper ref={ref} width={wrapperWidth} contentWidth={contentWidth}>
            <Content className="content">
                <Header>
                    <Brand className="apply-font-main apply-font-heading">
                        Logo
                    </Brand>
                    {!sizeIs.mobile && (
                        <>
                            <Menu>
                                <MenuItem className="apply-font-main">
                                    Home
                                </MenuItem>
                                <MenuItem className="apply-font-main">
                                    About
                                </MenuItem>
                                <MenuItem className="apply-font-main">
                                    Pricing
                                </MenuItem>
                                <MenuItem className="apply-font-main">
                                    Blog
                                </MenuItem>
                            </Menu>
                            <Buttons>
                                <Button
                                    accentColor={settings.accentColor}
                                    className="apply-font-main"
                                >
                                    Log in
                                </Button>
                                <ButtonInverted
                                    accentColor={settings.accentColor}
                                    className="apply-font-main"
                                >
                                    Register
                                </ButtonInverted>
                            </Buttons>
                        </>
                    )}
                    {sizeIs.mobile && (
                        <Button
                            className="apply-font-main"
                            accentColor={settings.accentColor}
                        >
                            Menu
                        </Button>
                    )}
                </Header>
            </Content>
            <Content full>
                <BackgroundCover src={WebsiteBg01} />
                <HeroContent isMobile={sizeIs.mobile}>
                    <H1
                        isMobile={sizeIs.mobile}
                        className="apply-font-main apply-font-heading"
                    >
                        Grow your customer base
                    </H1>
                    <P isMobile={sizeIs.mobile} className="apply-font-main">
                        Subtitle with some outfilling text, just a sentence.
                    </P>
                    <Button
                        accentColor={settings.accentColor}
                        className="apply-font-main"
                    >
                        Register now
                    </Button>
                </HeroContent>
            </Content>
            <Content className="content">
                <Columns isMobile={sizeIs.mobile}>
                    <Usp isMobile={sizeIs.mobile}>
                        <Rocket color={settings.accentColor.color} />
                        <UspText>
                            <H5
                                className="apply-font-main"
                                color={settings.textColor.color}
                            >
                                Rocket launch
                            </H5>
                            <UspParagraph
                                className="apply-font-main"
                                color={settings.textColor.color}
                            >
                                Leverage agile frameworks to provide a robust
                                synopsis for high level overviews.
                            </UspParagraph>
                        </UspText>
                    </Usp>
                    <Usp isMobile={sizeIs.mobile}>
                        <Meteor color={settings.accentColor.color} />
                        <UspText>
                            <H5
                                className="apply-font-main"
                                color={settings.textColor.color}
                            >
                                Meteor guarantee
                            </H5>
                            <UspParagraph
                                className="apply-font-main"
                                color={settings.textColor.color}
                            >
                                Organically grow the holistic world view of
                                disruptive innovation via workplace diversity
                                and empowerment.
                            </UspParagraph>
                        </UspText>
                    </Usp>
                    <Usp isMobile={sizeIs.mobile}>
                        <Atom color={settings.accentColor.color} />
                        <UspText>
                            <H5
                                className="apply-font-main"
                                color={settings.textColor.color}
                            >
                                It’s just science
                            </H5>
                            <UspParagraph
                                className="apply-font-main"
                                color={settings.textColor.color}
                            >
                                Capitalize on low hanging fruit to identify a
                                ballpark value added activity to beta test.
                            </UspParagraph>
                        </UspText>
                    </Usp>
                </Columns>
            </Content>
            <Content full>
                <BackgroundCover src={WebsiteBg02} />
                <HeroContent>
                    <BlockQuote isMobile={sizeIs.mobile}>
                        <p className="apply-font-main">
                            “Iterative approaches to corporate strategy foster
                            collaborative thinking to further the overall value
                            proposition.”
                        </p>
                        <footer className="apply-font-main">
                            John Wick
                            <cite className="apply-font-main">
                                General Manager
                            </cite>
                        </footer>
                    </BlockQuote>
                </HeroContent>
            </Content>
            <Content full backgroundColor={settings.accentColor.color}>
                <Content className="content">
                    <Columns isMobile={sizeIs.mobile}>
                        <Usp isMobile={sizeIs.mobile}>
                            <Rocket
                                color={settings.accentColor.contrastColor}
                            />
                            <UspText>
                                <H5
                                    className="apply-font-main"
                                    color={settings.accentColor.contrastColor}
                                >
                                    All the cookies
                                </H5>
                                <UspParagraph
                                    className="apply-font-main"
                                    color={settings.accentColor.contrastColor}
                                >
                                    Override the digital divide with additional
                                    clickthroughs from DevOps.
                                </UspParagraph>
                            </UspText>
                        </Usp>
                        <Usp isMobile={sizeIs.mobile}>
                            <Meteor
                                color={settings.accentColor.contrastColor}
                            />
                            <UspText>
                                <H5
                                    className="apply-font-main"
                                    color={settings.accentColor.contrastColor}
                                >
                                    Any device
                                </H5>
                                <UspParagraph
                                    className="apply-font-main"
                                    color={settings.accentColor.contrastColor}
                                >
                                    User generated content in real-time will
                                    have multiple touchpoints for offshoring.
                                </UspParagraph>
                            </UspText>
                        </Usp>
                        <Usp isMobile={sizeIs.mobile}>
                            <Atom color={settings.accentColor.contrastColor} />
                            <UspText>
                                <H5
                                    className="apply-font-main"
                                    color={settings.accentColor.contrastColor}
                                >
                                    No bugs
                                </H5>
                                <UspParagraph
                                    className="apply-font-main"
                                    color={settings.accentColor.contrastColor}
                                >
                                    Iterative approaches to corporate strategy
                                    foster collaborative thinking to further the
                                    overall value proposition.
                                </UspParagraph>
                            </UspText>
                        </Usp>
                    </Columns>
                </Content>
            </Content>
            <Content full backgroundColor={"#F5F6F9"}>
                <Content className="content">
                    <H3 className="apply-font-main apply-font-heading">
                        Pricing
                    </H3>
                    <Columns isMobile={sizeIs.mobile}>
                        <Card isMobile={sizeIs.mobile}>
                            <H5
                                color={settings.textColor.color}
                                className="apply-font-main"
                            >
                                Basic
                            </H5>
                            <Price
                                color={settings.accentColor.color}
                                isMobile={sizeIs.mobile}
                                className="apply-font-main apply-font-heading"
                            >
                                $5
                            </Price>
                            <ul className="apply-font-main">
                                <li>
                                    <Check color={settings.accentColor.color} />
                                    Leveragetical
                                </li>
                                <li>
                                    <Check color={settings.accentColor.color} />
                                    Synergetic
                                </li>
                                <li>
                                    <Check color={settings.accentColor.color} />
                                    SEO level 126
                                </li>
                                <li>
                                    <Check color={settings.accentColor.color} />
                                    Guru-like
                                </li>
                            </ul>
                            <Button
                                accentColor={settings.accentColor}
                                className="apply-font-main"
                            >
                                Register now
                            </Button>
                        </Card>
                        <Card isMobile={sizeIs.mobile}>
                            <H5
                                color={settings.textColor.color}
                                className="apply-font-main"
                            >
                                Premium
                            </H5>
                            <Price
                                color={settings.accentColor.color}
                                isMobile={sizeIs.mobile}
                                className="apply-font-main apply-font-heading"
                            >
                                $12
                            </Price>
                            <ul className="apply-font-main">
                                <li>
                                    <Check color={settings.accentColor.color} />
                                    Not basic
                                </li>
                                <li>
                                    <Check color={settings.accentColor.color} />
                                    Content
                                </li>
                                <li>
                                    <Check color={settings.accentColor.color} />
                                    SEO level 127
                                </li>
                                <li>
                                    <Check color={settings.accentColor.color} />
                                    Ninja-like
                                </li>
                            </ul>
                            <Button
                                accentColor={settings.accentColor}
                                className="apply-font-main"
                            >
                                Register now
                            </Button>
                        </Card>
                        <Card isMobile={sizeIs.mobile}>
                            <H5
                                color={settings.textColor.color}
                                className="apply-font-main"
                            >
                                Enterprise
                            </H5>
                            <Price
                                color={settings.accentColor.color}
                                isMobile={sizeIs.mobile}
                                className="apply-font-main apply-font-heading"
                            >
                                $29
                            </Price>
                            <ul className="apply-font-main">
                                <li>
                                    <Check color={settings.accentColor.color} />
                                    Not Premium
                                </li>
                                <li>
                                    <Check color={settings.accentColor.color} />
                                    One-stop-shop
                                </li>
                                <li>
                                    <Check color={settings.accentColor.color} />
                                    SEO level 128
                                </li>
                                <li>
                                    <Check color={settings.accentColor.color} />
                                    Rock-star-like
                                </li>
                            </ul>
                            <Button
                                accentColor={settings.accentColor}
                                className="apply-font-main"
                            >
                                Register now
                            </Button>
                        </Card>
                    </Columns>
                </Content>
            </Content>
            <Content className="content">
                <H3 className="apply-font-main apply-font-heading">News</H3>
                <Columns isMobile={sizeIs.mobile}>
                    <Article isMobile={sizeIs.mobile}>
                        <img src={News01} alt="" />
                        <NewsDate
                            color={settings.textColor.color}
                            className="apply-font-main"
                        >
                            May 24th 2020
                        </NewsDate>
                        <NewsH5
                            color={settings.textColor.color}
                            className="apply-font-main"
                        >
                            Blog post title
                        </NewsH5>
                        <NewsParagraph
                            color={settings.textColor.color}
                            className="apply-font-main"
                        >
                            Bring to the table win-win survival strategies to
                            ensure proactive domination. At the end of the day,
                            going forward, a new normal that has evolved from
                            generation X is on the runway heading towards a
                            streamlined cloud solution.
                        </NewsParagraph>
                        <Button
                            accentColor={settings.accentColor}
                            className="apply-font-main"
                        >
                            Read more
                        </Button>
                    </Article>
                    <Article isMobile={sizeIs.mobile}>
                        <img src={News02} alt="" />
                        <NewsDate
                            color={settings.textColor.color}
                            className="apply-font-main"
                        >
                            May 24th 2020
                        </NewsDate>
                        <NewsH5
                            color={settings.textColor.color}
                            className="apply-font-main"
                        >
                            Blog post title
                        </NewsH5>
                        <NewsParagraph
                            color={settings.textColor.color}
                            className="apply-font-main"
                        >
                            Bring to the table win-win survival strategies to
                            ensure proactive domination. At the end of the day,
                            going forward, a new normal that has evolved from
                            generation X is on the runway heading towards a
                            streamlined cloud solution.
                        </NewsParagraph>
                        <Button
                            accentColor={settings.accentColor}
                            className="apply-font-main"
                        >
                            Read more
                        </Button>
                    </Article>
                    <Article isMobile={sizeIs.mobile}>
                        <img src={News03} alt="" />
                        <NewsDate
                            color={settings.textColor.color}
                            className="apply-font-main"
                        >
                            May 24th 2020
                        </NewsDate>
                        <NewsH5
                            color={settings.textColor.color}
                            className="apply-font-main"
                        >
                            Blog post title
                        </NewsH5>
                        <NewsParagraph
                            color={settings.textColor.color}
                            className="apply-font-main"
                        >
                            Bring to the table win-win survival strategies to
                            ensure proactive domination. At the end of the day,
                            going forward, a new normal that has evolved from
                            generation X is on the runway heading towards a
                            streamlined cloud solution.
                        </NewsParagraph>
                        <Button
                            accentColor={settings.accentColor}
                            className="apply-font-main"
                        >
                            Read more
                        </Button>
                    </Article>
                </Columns>
            </Content>
            <Content full backgroundColor={"#0D1930"}>
                <Content className="content">
                    <ColumnsMiniFormWrapper
                        isMobile={sizeIs.mobile}
                        isTablet={sizeIs.tablet}
                    >
                        <H3
                            className="apply-font-main apply-font-heading"
                            noMargin={!sizeIs.desktop}
                            color={theme.colors.white}
                        >
                            Get the latest news
                        </H3>

                        <FormWrapper
                            isMobile={sizeIs.mobile}
                            isTablet={sizeIs.tablet}
                            isDesktop={sizeIs.desktop}
                        >
                            <InputSingleLine
                                type="email"
                                placeholder="name@address.com"
                                isMobile={sizeIs.mobile}
                                className="apply-font-main"
                            />
                            <ButtonSubmit
                                accentColor={settings.accentColor}
                                isMobile={sizeIs.mobile}
                                className="apply-font-main"
                            >
                                Sign me up!
                            </ButtonSubmit>
                        </FormWrapper>
                    </ColumnsMiniFormWrapper>
                </Content>
                <Content className="content apply-font-main">
                    <ListLinksWrapper isMobile={sizeIs.mobile}>
                        <ListLinks color={theme.colors.white}>
                            <h4>About</h4>
                            <ul>
                                <li>
                                    <a href="#1">Link 1</a>
                                </li>
                                <li>
                                    <a href="#2">Link 2</a>
                                </li>
                                <li>
                                    <a href="#3">Link 3</a>
                                </li>
                                <li>
                                    <a href="#4">Link 4</a>
                                </li>
                            </ul>
                        </ListLinks>
                        <ListLinks color={theme.colors.white}>
                            <h4>Product</h4>
                            <ul>
                                <li>
                                    <a href="#1">Link 1</a>
                                </li>
                                <li>
                                    <a href="#2">Link 2</a>
                                </li>
                                <li>
                                    <a href="#3">Link 3</a>
                                </li>
                                <li>
                                    <a href="#4">Link 4</a>
                                </li>
                            </ul>
                        </ListLinks>
                        <ListLinks color={theme.colors.white}>
                            <h4>Company</h4>
                            <ul>
                                <li>
                                    <a href="#1">Link 1</a>
                                </li>
                                <li>
                                    <a href="#2">Link 2</a>
                                </li>
                                <li>
                                    <a href="#3">Link 3</a>
                                </li>
                                <li>
                                    <a href="#4">Link 4</a>
                                </li>
                            </ul>
                        </ListLinks>
                        <ListLinks color={theme.colors.white}>
                            <h4>Follow us</h4>
                            <ul>
                                <li>
                                    <a href="#1">Facebook</a>
                                </li>
                                <li>
                                    <a href="#2">Instagram</a>
                                </li>
                                <li>
                                    <a href="#3">Twitter</a>
                                </li>
                                <li>
                                    <a href="#4">Dribbble</a>
                                </li>
                            </ul>
                        </ListLinks>
                    </ListLinksWrapper>
                </Content>
            </Content>
        </Wrapper>
    )
}

const Card = styled.div`
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    width: ${(props) => (props.isMobile ? "auto" : "calc(33% - 100px)")};
    text-align: center;
    padding: 40px;
    margin-bottom: ${(props) => (props.isMobile ? "20px" : "0")};
    display: ${(props) => (props.isMobile ? "flex" : "static")};
    flex-direction: column;
    ul {
        list-style: none;
        padding: 0;
        text-align: left;
        display: inline-flex;
        flex-direction: column;
        align-self: center;
        li {
            display: flex;
            align-items: flex-start;
            margin-bottom: ${(props) => (props.isMobile ? "4px" : "16px")};
            font-size: ${(props) => (props.isMobile ? "13px" : "17px")};
            color: ${(props) => props.color};
            > svg {
                margin-top: -3px;
                padding-right: 5px;
            }
        }
    }
    h5 {
        margin: 0;
    }
`

const Price = styled.p`
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: ${(props) => (props.isMobile ? "40px" : "54px")};
    line-height: 150%;
    text-align: center;
    color: ${(props) => props.color};
    margin: ${(props) => (props.isMobile ? "8px 0 0px 0" : "8px 0 20px 0")};
`

const Wrapper = styled.div`
    position: relative;
    display: flex;
    transition: width 225ms ease-out;
    flex-direction: column;
    align-items: center;
    width: ${(props) => props.width + "px"};
    .content {
        max-width: ${(props) => props.contentWidth}px;
    }
`

const Content = styled.div`
    padding: ${(props) => (props.full ? "0" : "40px 25px")};
    position: relative;
    background: ${(props) => props.backgroundColor || "none"};
    color: ${(props) => props.contrastColor || "#000000"};
    margin-bottom: -3px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    flex: 1 0 auto;
`

const HeroContent = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    > h1 {
        width: ${(props) => (props.isMobile ? "100%" : "66%")};
        align-self: center;
    }
`

const BackgroundCover = styled.img`
    max-width: 100%;
    width: 100%;
`

const H1 = styled.h1`
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: ${(props) => (props.isMobile ? "24px" : "64px")};
    line-height: 100%;
    text-align: center;
    color: ${(props) => props.color};
    margin: 0 0 ${(props) => (props.isMobile ? "0" : "24px")} 0;
    padding: 0;
`

const P = styled.p`
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: ${(props) => (props.isMobile ? "14px" : "20px")};
    line-height: 26px;
    text-align: center;
    color: ${(props) => props.color};
    padding: 0;
`

const H3 = styled.h3`
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 38px;
    line-height: 1.25;
    color: ${(props) => props.color};
    text-align: center;
    margin: 0 0 ${(props) => (props.noMargin ? "0" : "40px")} 0;
`

const H5 = styled.h5`
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 26px;
    color: ${(props) => props.color};
    margin: 0 0 0 20px;
`

const Header = styled.header`
    display: flex;
    flex: 1 1 auto;
    align-items: center;
    justify-content: space-between;
    align-self: stretch;
    padding: 25px 0;
`

const Brand = styled.div`
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    text-align: center;
    color: ${(props) => props.color};
`

const Menu = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
`

const MenuItem = styled.li`
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 17px;
    line-height: 22px;
    text-align: center;
    color: ${(props) => props.color};
    padding: 0 20px;
`

const Buttons = styled.div`
    display: flex;
    > a {
        margin: 0 0 0 10px;
    }
`

const Button = styled.a`
    background: ${(props) => props.accentColor.color};
    color: ${(props) => props.accentColor.contrastColor};
    border: 1px solid ${(props) => props.accentColor.color};
    &:hover {
        background: ${(props) => props.accentColor.contrastColor};
        color: ${(props) => props.accentColor.color};
    }
    cursor: pointer;
    height: 40px;
    border-radius: 20px;
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 22px;
    padding: 0 25px;
    display: inline-flex;
    flex: 0 1 auto;
    align-items: center;
    align-self: center;
    white-space: nowrap;
`

const ButtonInverted = styled(Button)`
    background: ${(props) => props.accentColor.contrastColor};
    color: ${(props) => props.accentColor.color};
    border: 1px solid ${(props) => props.accentColor.color};
    &:hover {
        background: ${(props) => props.accentColor.color};
        color: ${(props) => props.accentColor.contrastColor};
    }
`

const FormWrapper = styled.div`
    background-color: white;
    border-radius: 8px;
    padding: 32px;
    display: flex;
    flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
    flex-basis: ${(props) => (props.isDesktop ? "100%" : "50%")};
    align-self: stretch;
`

const ButtonSubmit = styled(Button)`
    border: 1px solid ${(props) => props.accentColor.color};
    margin-left: ${(props) => (props.isMobile ? "0" : "-44px")};
    height: 54px;
    border-radius: 27px;
    margin-bottom: ${(props) => (props.isMobile ? "12px" : "0")};
`

const InputSingleLine = styled.input`
    height: 52px;
    min-height: 52px;
    border-radius: 26px;
    font-family: Inter;
    font-style: normal;
    border: 1px solid #d3d6dd;
    padding-left: 24px;
    padding-right: 24px;
    font-size: 17px;
    flex: 1;
    margin-bottom: ${(props) => (props.isMobile ? "12px" : "0")};
`

const Columns = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
    width: ${(props) => (props.isMobile ? "75%" : "auto")};
`

const ColumnsMiniFormWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    flex-direction: ${(props) =>
        props.isTablet || props.isMobile ? "column" : "row"};
    width: ${(props) => (props.isMobile ? "90%" : "100%")};
    align-items: center;
`

const Usp = styled.div`
    display: flex;
    flex: 1 1 33%;
    padding: ${(props) =>
        props.isMobile ? "20px 12px 20px 0" : "80px 12px 80px 0"};
`

const UspText = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
`

const UspParagraph = styled.p`
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 150%;
    color: ${(props) => props.color};
    margin: 8px 0 0 20px;
`

const BlockQuote = styled.blockquote`
    p {
        font-style: normal;
        font-weight: normal;
        font-size: ${(props) => (props.isMobile ? "16px" : "32px")};
        line-height: 130%;
        text-align: center;
        color: #ffffff;
        margin-top: ${(props) => (props.isMobile ? "0" : "16px")};
        width: ${(props) => (props.isMobile ? "auto" : "75%")};
        margin-left: auto;
        margin-right: auto;
    }
    footer {
        font-style: normal;
        font-weight: bold;
        font-size: ${(props) => (props.isMobile ? "13px" : "17px")};
        line-height: 130%;
        text-align: center;
        color: #ffffff;
        cite {
            font-style: normal;
            font-weight: normal;
            line-height: 130%;
            text-align: center;
            color: #ffffff;
            display: block;
        }
    }
`

const Article = styled.article`
    width: ${(props) => (props.isMobile ? "auto" : "calc(33.33% - 16px)")};
    margin-bottom: ${(props) => (props.isMobile ? "40px" : "0")};
    img {
        max-width: 100%;
    }
`

const NewsH5 = styled(H5)`
    margin: 8px 0 16px 0;
`

const NewsParagraph = styled.p`
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 150%;
    color: ${(props) => props.color};
`

const NewsDate = styled.p`
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 150%;
    text-transform: uppercase;
    color: ${(props) => props.color};
`

const ListLinksWrapper = styled(Columns)`
    align-self: stretch;
    border-top: 1px solid #49546a;
    padding-top: 60px;
    flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
    width: 100%;
    align-items: center;
`

const ListLinks = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1 1 25%;
    padding: 0;
    margin-bottom: 24px;
    width: 90%;
    ul li,
    ul li a,
    h4 {
        color: ${(props) => props.color};
    }
    h4 {
        margin: 0 0 6px 0;
        line-height: 1.5;
        font-size: 20px;
    }
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li a {
            line-height: 1.5;
            text-decoration: none;
            font-size: 17px;
            &:hover {
                opacity: 0.7;
            }
        }
    }
`

export default Website
