import React, { createContext, useContext, useReducer } from "react"
import { createBrowserHistory } from "history"
import defaultSettings from "./settingsDefaults"
import serializeObjectToQueryString from "./serializeObjectToQueryString"

const SettingsStateContext = createContext()
const SettingsDispatchContext = createContext()

const setQueryString = (string) => {
    const history = createBrowserHistory()
    history.replace("?" + string)
}

function settingsReducer(state, action) {
    switch (action.type) {
        case "SET_MAIN_FONT": {
            const newState = {
                ...state,
                fonts: { ...state.fonts, main: action.fonts.main }
            }
            return newState
        }
        case "SET_HEADING_FONT": {
            const newState = {
                ...state,
                fonts: { ...state.fonts, heading: action.fonts.heading }
            }
            return newState
        }
        case "SET_COLORS": {
            const newState = { ...state, ...action.colors }
            return newState
        }
        case "SET_APP_BACKGROUND_COLOR": {
            const newState = {
                ...state,
                appBackgroundColor: action.appBackgroundColor
            }
            return newState
        }
        case "UPDATE_URL_QUERY": {
            setQueryString(serializeObjectToQueryString(state))
            return state
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`)
        }
    }
}

function SettingsProvider({ children }) {
    const [state, dispatch] = useReducer(settingsReducer, defaultSettings())
    return (
        <SettingsStateContext.Provider value={state}>
            <SettingsDispatchContext.Provider value={dispatch}>
                {children}
            </SettingsDispatchContext.Provider>
        </SettingsStateContext.Provider>
    )
}

function useSettingsState() {
    const context = useContext(SettingsStateContext)
    if (context === undefined) {
        throw new Error(
            "useSettingsState must be used within a SettingsProvider"
        )
    }
    return context
}

function useSettingsDispatch() {
    const context = useContext(SettingsDispatchContext)
    if (context === undefined) {
        throw new Error(
            "useSettingsDispatch must be used within a SettingsProvider"
        )
    }
    return context
}

export { SettingsProvider, useSettingsState, useSettingsDispatch }
