import React from "react"
import styled from "styled-components/macro"
import Box from "../../components/Box"

import Brand from "../../components/Brand"
import Paragraph from "../../components/Paragraph"

const Waiting = (props) => {
    return (
        <WaitingWrapper>
            <Brand />
            <Box>
                <Paragraph>Waiting for live preview to start...</Paragraph>
            </Box>
        </WaitingWrapper>
    )
}

const WaitingWrapper = styled.div`
    flex-direction: column;
    width: 100%;
`

export default Waiting
