import * as React from "react"

function SvgMeteor(props) {
    const size = props.size || 24
    const color = props.color || "#5A3ADF"
    return (
        <svg
            width={size}
            height={size}
            fill="none"
            viewBox="0 0 48 48"
            {...props}
        >
            <path
                d="M19 44c4.006 0 7.774-1.56 10.626-4.414l13.808-14.192A2.001 2.001 0 0042 22h-6.602l8.35-15.028a2 2 0 00-2.718-2.72l-14.22 7.9 1.152-5.758a1.998 1.998 0 00-3.258-1.914L8.392 18.393c-5.848 5.848-5.848 15.364 0 21.212A14.905 14.905 0 0019 44zm-7.896-22.67l11.804-10.062-.496 2.48-.372 1.86v.002l-.848 4.238 15.66-8.7-6.6 11.88s0 .002-.002.002L28.602 26h8.662L26.778 36.778A10.927 10.927 0 0119 39.999a10.932 10.932 0 01-7.78-3.221C6.934 32.49 6.934 25.51 11.104 21.33z"
                fill={color}
            />
            <path
                d="M19 36a7 7 0 007-7c0-2.8-1.656-5.2-4.032-6.318.01.106.032.21.032.318a3 3 0 11-6 0c0-.108.022-.212.032-.318C13.656 23.8 12 26.2 12 29a7 7 0 007 7z"
                fill={color}
            />
        </svg>
    )
}

export default SvgMeteor
