import React, { useState, useEffect, useContext } from "react"
import styled, { ThemeContext } from "styled-components/macro"
import Bleed from "../../../components/Preview/Bleed"
import { BodySmall } from "../../../components/Preview/BodyText"
import { ButtonPrimary } from "../../../components/Preview/Buttons"
import Header from "../../../components/Preview/Header"
import { H1, H3, H4 } from "../../../components/Preview/Headings"
import Preview from "../../../components/Preview/Preview"
import Section from "../../../components/Preview/Section"
import { useSettingsState } from "../../../utils/settingsContext"
import TabWrapper from "../../../components/Preview/TabWrapper"

import Stock01 from "../../../assets/images/home-stock-01.png"
import Stock02 from "../../../assets/images/home-stock-02.png"
import Stock03 from "../../../assets/images/home-stock-03.png"
import Stock04 from "../../../assets/images/home-stock-04.png"
import Stock05 from "../../../assets/images/home-stock-05.png"

const Home = ({ liveMode = false, liveSettings = null }) => {
    const theme = useContext(ThemeContext)
    // let settings
    const settingsState = useSettingsState()

    /*if (liveSettings || liveMode) {
        console.log("Have props liveSettings", liveSettings)
        settings = liveSettings
    } else {
        console.log("No settings by props, using state/context")
        settings = settingsState
    }*/

    const [settings, setSettings] = useState(null)
    useEffect(() => {
        if (liveSettings || liveMode) {
            // console.log("Have props liveSettings", liveSettings)
            if (liveSettings) {
                setSettings(liveSettings)
            }
        } else {
            // console.log("No settings by props, using state/context")
            setSettings(settingsState)
        }
    }, [liveMode, liveSettings, settingsState])

    const baseSpacing = 16

    const newsList = [
        {
            title: "Nobels fredspris til Verdens matvareprogram",
            source: "Dagbladet",
            sourceUrl: "https://db.no",
            timeAgo: "2 minutter siden",
            thumbnail: Stock01
        },
        {
            title: "Oslo Børs stiger fra start – nykommer stiger kraftig",
            source: "E24",
            sourceUrl: "https://db.no",
            timeAgo: "6 timer siden",
            thumbnail: Stock02
        },
        {
            title: "Nå kommer skredet av superkjappe SSD-er for gaming",
            source: "ITavisen.no",
            sourceUrl: "https://db.no",
            timeAgo: "2 timer siden",
            thumbnail: Stock03
        },
        {
            title:
                "Apple-lekkasje: Ny Iphone vil slå knockout på konkurrentene på pris",
            source: "Nettavisen",
            sourceUrl: "https://db.no",
            timeAgo: "5 timer siden",
            thumbnail: Stock04
        },
        {
            title: "Slik ruster NRK Og TV 2 seg i kampen om julens klassikere",
            source: "VG",
            sourceUrl: "https://db.no",
            timeAgo: "3 timer siden",
            thumbnail: Stock05
        }
    ]

    const getSingleNews = (news, index) => {
        return (
            <News
                key={`news-${index}`}
                href={news.sourceUrl}
                onClick={(event) => {
                    event.preventDefault()
                    return false
                }}
            >
                <ThumbnailWrapper>
                    <Thumbnail src={news.thumbnail} />
                </ThumbnailWrapper>
                <NewsText>
                    <H3
                        textColor={settings.textColor.color}
                        className="apply-font-main apply-font-heading"
                    >
                        {news.title}
                    </H3>
                    <BodySmall
                        textColor={settings.textColor.color}
                        bottomMargin={false}
                        className="apply-font-main"
                    >
                        {news.source} • {news.timeAgo}
                    </BodySmall>
                </NewsText>
            </News>
        )
    }

    const getNewsList = () => {
        return (
            <NewsList>
                {newsList.map((news, index) => getSingleNews(news, index))}
            </NewsList>
        )
    }

    return (
        <TabWrapper isLivePreview={liveMode}>
            {settings && (
                <Preview
                    className="preview-mobile"
                    baseSpacing={baseSpacing}
                    bgColor={theme.colors.gray7}
                >
                    <Bleed baseSpacing={baseSpacing} sticky>
                        <Header baseSpacing={baseSpacing}>
                            <H1
                                className="apply-font-main apply-font-heading"
                                textColor={settings.textColor.color}
                            >
                                Home
                            </H1>
                        </Header>
                    </Bleed>

                    <Bleed baseSpacing={baseSpacing}>
                        <Section baseSpacing={baseSpacing} first>
                            <H4
                                className="apply-font-main apply-font-heading"
                                textColor={settings.textColor.color}
                            >
                                Latest news
                            </H4>
                            {getNewsList()}
                            <ButtonPrimary
                                accentColor={settings.accentColor}
                                className="apply-font-main"
                            >
                                Load more news
                            </ButtonPrimary>
                        </Section>
                    </Bleed>
                </Preview>
            )}
        </TabWrapper>
    )
}

const NewsList = styled.div``

const News = styled.a`
    display: flex;
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(6, 28, 38, 0.1);
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 16px;
    color: inherit;
    text-decoration: none;
`

const ThumbnailWrapper = styled.div`
    width: 64px;
    height: 64px;
    background-color: lightgray;
    flex-shrink: 0;
`

const Thumbnail = styled.img`
    border-radius: 2px;
    width: 100%;
`

const NewsText = styled.div`
    margin-left: 16px;
`

export default Home
