import * as React from "react"

function SvgCellular(props) {
    return (
        <svg width={18} height={12} fill="none" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.667.667h-1a1 1 0 00-1 1v8.666a1 1 0 001 1h1a1 1 0 001-1V1.667a1 1 0 00-1-1zM11 3h1a1 1 0 011 1v6.333a1 1 0 01-1 1h-1a1 1 0 01-1-1V4a1 1 0 011-1zM7.333 5.333h-1a1 1 0 00-1 1v4a1 1 0 001 1h1a1 1 0 001-1v-4a1 1 0 00-1-1zm-4.666 2h-1a1 1 0 00-1 1v2a1 1 0 001 1h1a1 1 0 001-1v-2a1 1 0 00-1-1z"
                fill="#000"
            />
        </svg>
    )
}

export default SvgCellular
