import React from "react"
import styled from "styled-components/macro"

const Header = ({ baseSpacing = 16, shadow = true, children }) => {
    return (
        <StyledHeader baseSpacing={baseSpacing} shadow={shadow}>
            {children}
        </StyledHeader>
    )
}

const StyledHeader = styled.div`
    padding: 44px ${(props) => props.baseSpacing}px 16px;
    background: #ffffff;
    ${(props) => props.shadow && "box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1)"};
`

export default Header
