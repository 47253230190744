import * as React from "react"

function SvgBattery(props) {
    return (
        <svg width={25} height={12} fill="none" {...props}>
            <path
                opacity={0.4}
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2.667 1.333h16.666C20.253 1.333 21 2.08 21 3v6c0 .92-.746 1.667-1.667 1.667H2.667C1.747 10.667 1 9.92 1 9V3c0-.92.746-1.667 1.667-1.667zM0 3A2.667 2.667 0 012.667.333h16.666A2.667 2.667 0 0122 3v6a2.667 2.667 0 01-2.667 2.667H2.667A2.667 2.667 0 010 9V3zm24.328 3A2.17 2.17 0 0123 8V4a2.17 2.17 0 011.328 2z"
                fill="#000"
            />
            <rect
                x={2}
                y={2.333}
                width={18}
                height={7.333}
                rx={1.333}
                fill="#000"
            />
        </svg>
    )
}

export default SvgBattery
