import React from "react"
import styled from "styled-components/macro"

import Box from "../components/Box"
import SvgLogoSymbol from "../assets/icons/LogoSymbol"

import { H1 } from "../components/Heading"
import Paragraph from "../components/Paragraph"

const Brand = (props) => {
    return (
        <BoxBranded>
            <Horizontal>
                <SvgLogoSymbol />
                <H1>StyleTile</H1>
            </Horizontal>
            <Paragraph>Create accessible, digital branding fast.</Paragraph>
        </BoxBranded>
    )
}

const Horizontal = styled.div`
    display: flex;
    align-items: center;
`

const BoxBranded = styled(Box)`
    background-color: ${(props) => props.theme.colors.blueMain};
    h1,
    p {
        color: white;
    }
`

export default Brand
