import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components/macro"

const Switcher = ({ options, defaultOption, onChange }) => {
    const [containerWidth, setContainerWidth] = useState(null)
    const [indicatorPosition, setIndicatorPosition] = useState(0)
    const [selectedOption, setSelectedOption] = useState(0)

    const refContainer = useRef(null)
    useEffect(() => {
        if (refContainer.current) {
            setContainerWidth(refContainer.current.offsetWidth)
        }
    }, [refContainer])

    const handleOnClick = (index) => {
        setSelectedOption(index)
        onChange(options[index].title)
    }

    useEffect(() => {
        const indicatorWidth = containerWidth / options.length
        const newPosition = indicatorWidth * selectedOption
        setIndicatorPosition(newPosition - (selectedOption !== 0 ? 9 : 0))
    }, [selectedOption])

    useEffect(() => {
        if (defaultOption) {
            const index = options.findIndex(
                (option) => option.title === defaultOption
            )
            setSelectedOption(index)
        }
    }, [defaultOption])

    return (
        <SwitcherContainer ref={refContainer}>
            <Indicator
                optionCount={options.length}
                style={{ left: indicatorPosition }}
            />
            {options.map((option, index) => {
                return (
                    <SwitcherOption
                        key={"option" + index}
                        onClick={() => handleOnClick(index)}
                        optionCount={options.length}
                    >
                        {option.icon || option.title}
                    </SwitcherOption>
                )
            })}
        </SwitcherContainer>
    )
}

const SwitcherContainer = styled.div`
    background: #e9ebef;
    border: 1px solid #d3d6dd;
    box-sizing: border-box;
    border-radius: 8px;
    height: 42px;
    display: flex;
    position: relative;
    margin: 16px 0 0 0;
`

const SwitcherOption = styled.button`
    font-family: "Basier Circle Medium";
    font-weight: normal;
    box-shadow: none;
    background: none;
    border: none;
    font-size: 15px;
    display: flex;
    align-items: center;
    flex: 1 1 ${(props) => 100 / props.optionCount + "%"};
    justify-content: center;
    z-index: 1024;
    &:focus {
        outline: none;
    }
`

const Indicator = styled.div`
    background: #ffffff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    width: ${(props) => 100 / props.optionCount + "%"};
    margin: 4px;
    position: absolute;
    height: calc(100% - 8px);
    z-index: 1000;
    transition: left 225ms ease-in-out;
`

export default Switcher
