import React, { useState } from "react"
import NavigationBar from "./NavigationBar"

import { SCREEN, APP_BACKGROUND_COLOR_FOR_SCREEN } from "../../../utils/enums"
import Home from "./Home"
import Discover from "./Discover"
import Profile from "./Profile"
import Settings from "./Settings"

import { useSettingsDispatch } from "../../../utils/settingsContext"
import useDebounce from "../../../utils/useDebounce"

const AppPreview = ({
    liveMode = false,
    inAppPreview = false,
    inDeviceSimulator = false,
    liveSettings
}) => {
    const [currentScreen, setCurrentScreen] = useState(SCREEN.HOME)

    const settingsDispatch = useSettingsDispatch()
    const updateURLQuery = useDebounce(() => {
        settingsDispatch({
            type: "UPDATE_URL_QUERY"
        })
    }, 200)

    const handleOnNavigation = (toScreen) => {
        setCurrentScreen(toScreen)
        settingsDispatch({
            type: "SET_APP_BACKGROUND_COLOR",
            appBackgroundColor: APP_BACKGROUND_COLOR_FOR_SCREEN[toScreen]
        })
        if (!liveMode) {
            updateURLQuery()
        }
    }

    return (
        <>
            {currentScreen === SCREEN.HOME && (
                <Home
                    liveMode={liveMode}
                    inAppPreview
                    liveSettings={liveSettings}
                />
            )}
            {currentScreen === SCREEN.DISCOVER && (
                <Discover liveMode={liveMode} liveSettings={liveSettings}>
                    DISCOVER
                </Discover>
            )}
            {currentScreen === SCREEN.PROFILE && (
                <Profile liveMode={liveMode} liveSettings={liveSettings}>
                    PROFILE
                </Profile>
            )}
            {currentScreen === SCREEN.SETTINGS && (
                <Settings liveMode={liveMode} liveSettings={liveSettings}>
                    SETTINGS
                </Settings>
            )}
            <NavigationBar
                onNavigation={handleOnNavigation}
                liveSettings={liveSettings}
                inAppPreview={inAppPreview}
                inDeviceSimulator={inDeviceSimulator}
                liveMode={liveMode}
            />
        </>
    )
}

export default AppPreview
