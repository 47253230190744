import React from "react"

import BoxHeader from "../components/BoxHeader"
import SingleColor from "./SingleColor"

import defaultSettings from "../utils/settingsDefaults"

const ColorSection = (props) => {
    const defaultSetup = [
        {
            title: "Accent color",
            colorKey: "accentColor",
            colors: [defaultSettings().accentColor.color], // ["#5A3ADF"],
            active: true
        },
        {
            title: "Text color",
            colorKey: "textColor",
            colors: [defaultSettings().textColor.color], // ["#343434"],
            active: true
        },
        {
            title: "UI Colors",
            colorKey: "uiColors",
            colors: ["#595959", "#C2C2C2", "#D8D8D8", "#ECECEC"],
            active: false
        }
    ]

    // const resetColors = () => {}

    return (
        <>
            <BoxHeader /* onResetClick={resetColors} */>Colors</BoxHeader>
            {defaultSetup
                .filter((color) => color.active)
                .map((color) => {
                    return (
                        <SingleColor
                            key={color.colorKey}
                            colorKey={color.colorKey}
                            title={color.title}
                            colors={color.colors}
                        />
                    )
                })}
        </>
    )
}

export default ColorSection
