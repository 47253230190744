import React from "react"
import styled from "styled-components/macro"
import useLongPress from "../utils/useLongPress"

const ColorSwatch = ({
    color,
    onShortClick,
    onUpdatesSwatches,
    inList = false,
    size = 24
}) => {
    const deleteSwatch = (color) => {
        if (onUpdatesSwatches) {
            const swatches = localStorage.getItem("agens.styletile.swatches")
            if (swatches) {
                const allSwatches = JSON.parse(swatches)
                const newSwatches = allSwatches.filter(
                    (swatch) => swatch.toLowerCase() !== color.toLowerCase()
                )
                localStorage.setItem(
                    "agens.styletile.swatches",
                    JSON.stringify(newSwatches)
                )
                onUpdatesSwatches(newSwatches)
            }
        }
    }

    const longPressEvent = useLongPress(
        () => deleteSwatch(color),
        onShortClick,
        {
            delay: 1000
        }
    )
    return (
        <StyledColorSwatch
            size={size}
            inList={inList}
            color={color}
            {...longPressEvent}
        />
    )
}

const StyledColorSwatch = styled.div`
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
    border: 1px solid ${(props) => props.theme.colors.gray4};
    border-radius: 50%;
    background-color: ${(props) => props.color};
    display: flex;
    flex-shrink: 0;
    margin: ${(props) => (props.inList ? "0 12px 0 0" : "0")};
    cursor: ${(props) => (props.inList ? "pointer" : "default")};
`

export default ColorSwatch
