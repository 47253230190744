import * as React from "react"

function SvgChevronUp(props) {
    return (
        <svg width={24} height={24} fill="none" {...props}>
            <path
                d="M6.293 13.293l1.414 1.414L12 10.414l4.293 4.293 1.414-1.414L12 7.586l-5.707 5.707z"
                fill="#0D1930"
            />
        </svg>
    )
}

export default SvgChevronUp
