function defaultColors() {
    return {
        accentColor: {
            color: "#5A3ADF",
            contrastColor: "#FFFFFF"
        },
        textColor: {
            color: "#343434",
            contrastColor: "#FFFFFF"
        },
        uiColors: {}
    }
}

export default defaultColors
