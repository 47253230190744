import * as React from "react"

function SvgCompass({ color = "#0D1930", size = 24 }) {
    return (
        <svg width={size} height={size} fill="none">
            <path
                d="M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm0 18c-4.411 0-8-3.589-8-8s3.589-8 8-8 8 3.589 8 8-3.589 8-8 8z"
                fill={color}
            />
            <path d="M8 16l5.991-2L16 8l-6 2-2 6z" fill={color} />
        </svg>
    )
}

export default SvgCompass
