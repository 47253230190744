import React from "react"
import styled from "styled-components/macro"

export const BodySmall = ({
    textColor,
    bottomMargin = true,
    className,
    children
}) => {
    return (
        <StyledBodySmall
            bottomMargin={bottomMargin}
            className={className}
            textColor={textColor}
        >
            {children}
        </StyledBodySmall>
    )
}

const StyledBodySmall = styled.p`
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    color: ${(props) => props.textColor};
    margin: 0 0 ${(props) => (props.bottomMargin ? "8px" : "0")} 0;
`

export const BodyLarge = ({
    textColor,
    bottomMargin = true,
    className,
    children
}) => {
    return (
        <StyledBodyLarge
            bottomMargin={bottomMargin}
            className={className}
            textColor={textColor}
        >
            {children}
        </StyledBodyLarge>
    )
}

const StyledBodyLarge = styled.p`
    font-family: Inter;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color: ${(props) => props.textColor};
    margin: 0 0 ${(props) => (props.bottomMargin ? "8px" : "0")} 0;
`
