import React from "react"
import styled from "styled-components/macro"
import { Plus } from "../assets/icons"

const AddButton = ({ text, onClick }) => {
    return (
        <StyledAddButton onClick={onClick}>
            <Circled>
                <Plus />
            </Circled>
            {text}
        </StyledAddButton>
    )
}

const StyledAddButton = styled.button`
    font-family: "Basier Circle";
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    font-size: 17px;
    margin-top: 16px;
`

const Circled = styled.span`
    background-color: ${(props) => props.theme.colors.blueLight};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-right: 8px;
`

export default AddButton
